import React from 'react'
import { cn } from '../utils/helperFunctions'

const SectionBody = ({ children, className, ...props }) => {
  return (
    <section
      {...props}
      className={cn('flex w-full flex-col gap-3 pt-3', className)}
    >
      {children}
    </section>
  )
}

export default SectionBody
