import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
export const cn = (...args) => {
  return twMerge(clsx(...args))
}
//This function is used to check if data is primitive or not, to avoid error rendering
export const isPrimitiveDataType = (value) => {
  if (Array.isArray(value)) return false
  if (typeof value === 'object') return false
  return true
}
export const convertToTitle = (str = '') => {
  str = str.replace(/_/g, ' ').replace(/^./, (s) => s.toUpperCase())
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase())
}

export const isObject = (value) => {
  if (Array.isArray(value)) return false
  return typeof value === 'object' && value !== null
}

export const convertArrayToObject = (array) => {
  return array.reduce((acc, item) => {
    acc[item.key] = item.value
    return acc
  }, {})
}
