import React from 'react'
import { cn } from '../../utils/helperFunctions'

const Modal = ({ children, className, ...props }) => {
  return (
    <div
      className={cn(
        'absolute left-[50%] top-[50%] z-20 translate-x-[-50%] translate-y-[-50%]',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export default Modal
