import React from 'react'
import { cn } from '../../utils/helperFunctions'
import { tableCellClasses } from '.'

export const TableHeader = ({ className, children, ...props }) => {
  return (
    <th
      className={cn('font-[600] capitalize', tableCellClasses, className)}
      {...props}
    >
      {children}
    </th>
  )
}
