//create slice for sidebar
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: true,
}

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    closeSidebar: (state) => {
      state.open = false
    },
    openSidebar: (state) => {
      state.open = true
    },
  },
})

export const { closeSidebar, openSidebar } = sidebarSlice.actions
export default sidebarSlice.reducer
