import instance from './axios-instance'

export const getAllParentCategories = async (keyword = '', page = 1, select = 1) => {
  const { data } = await instance.get(
    `/api/v1/parentCategory?keyword=${keyword}&page=${page}&select=${select}`,
  )
  return data
}

export const getAllparentCategory = async () => {
  const { data } = await instance.get('/api/v1/allparentCategory')
  return data
}

export const createParentCategory = async (payload) => {
  const { data } = await instance.post('/api/v1/admin/parentCategory', payload)
  return data
}
export const getParentCategoryAttributes = async (id) => {
  const { data } = await instance.get(`/api/v1/parentCategory/${id}/attribute`)
  return data
}
export const getParentCategory = async (id) => {
  const { data } = await instance.get(`/api/v1/parentCategory/${id}`)
  return data
}

export const updateParentCategory = async ({ id, payload }) => {
  const { data } = await instance.put(
    `/api/v1/admin/parentCategory/${id}`,
    payload,
  )
  return data
}
export const deleteParentCategory = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/parentCategory/${id}`)
  return data
}

