import { createColumnHelper } from '@tanstack/react-table'
import { NavLink } from 'react-router-dom'
import ActionButton from '../../components/shared/ActionButton'
import { EditIcon, ViewIcon } from '../../components/shared/Icons'
const columnHelper = createColumnHelper()
export const columns = [
  columnHelper.accessor('_id', {
    id: 'S/N',
    header: 'ID',
    cell: (info) => info.row.index + 1,
  }),
  columnHelper.accessor('email', {
    header: 'email',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('createdAt', {
    header: 'Created At',
    cell: (info) => info.getValue(),
  }),
]
