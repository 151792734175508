import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Button from '../../components/shared/Button'
import { EditIcon } from '../../components/shared/Icons'
import Loader from '../../components/shared/Loader'
import Record from '../../components/shared/Record'
import useFetch from '../../hooks/useFetch'
import ViewLayout from '../../layouts/ViewLayout'
import { getAttribute } from '../../utils/Apis/attributes'
import { formatDate } from '../../utils/formatDate'
const ViewAttribute = () => {
  const { id } = useParams()
  const { data, loading } = useFetch({ fn: () => getAttribute(id) })
  const attribute = data?.attribute
  return (
    <ViewLayout
      sectionTitle={'Attributes Details'}
      allButtonTitle={'All Attributes'}
      allButtonPath={'/attributes'}
      action={
        <Button>
          <EditIcon />
          <NavLink to={`/attributes/edit/${id}`}>Update Attribute</NavLink>
        </Button>
      }
    >
      {loading ? (
        <div className="flex w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <Record label={'Name'} value={attribute?.name} />
          <Record
            label={'Created At'}
            value={formatDate(attribute?.createdAt)}
          />
          <div className="flex flex-col gap-2">
            <span className="font-semibold">Categories Applicable:</span>
            <div className="flex flex-wrap gap-2">
              {attribute?.parentCategories?.map((category) => (
                <div
                  key={category._id}
                  className="rounded-md bg-muted p-2 text-lg"
                >
                  {category.name}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </ViewLayout>
  )
}

export default ViewAttribute
