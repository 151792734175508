import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import DeleteButton from '../../components/shared/DeleteButton'
import { DeleteIcon } from '../../components/shared/Icons'
import Loader from '../../components/shared/Loader'
import useFetch from '../../hooks/useFetch'

import { cn } from '../../utils/helperFunctions'
import {
  deleteCustomer,
  getCustomerById,
  updateCustomer,
} from '../../utils/Apis/customers'
import { withErrorHandling } from '../../utils/withErrorHandling'
import ModalContainer from '../../components/ModalContainer'
import Modal from '../../components/ModalContainer/Modal'
import Overlay from '../../components/ModalContainer/Overlay'
const EditCustomer = ({ className, ...props }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, error, loading } = useFetch({
    fn: () => getCustomerById(id),
  })
  const [open, setOpen] = useState(false)
  const handleDelete = withErrorHandling(async () => {
    setOpen(false)
    const response = await deleteCustomer(id)
    if (response?.success) {
      toast.success('User Deleted Successfully')
      setTimeout(() => {
        navigate('/customers')
      }, 2000)
    }
  })
  const customer = data?.user
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onBlur',
  })
  const onSubmit = withErrorHandling(async (data) => {
    const payload = {
      ...data,
      CompanyName: data?.companyName,
      role: 'user',
    }
    const response = await updateCustomer({ id, payload })
    if (response?.user) {
      toast.success('User Updated Successfully')
      setTimeout(() => {
        navigate(`/customers/view/${response.user._id}`)
      }, 2000)
    }
  })

  useEffect(() => {
    if (customer) {
      reset({
        ...customer,
        companyName: customer?.CompanyName,
      })
    }
  }, [customer])
  const fields = [
    {
      label: 'Name',
      id: 'name',
      name: 'name',
      register: register('name', {
        required: 'Name is required',
      }),
      errorMsg: errors?.name?.message,
      required: true,
    },
    {
      label: 'Email',
      id: 'email',
      name: 'email',
      type: 'email',
      register: register('email', {
        required: 'Email is required',
      }),
      errorMsg: errors?.email?.message,
    },
    {
      label: 'Phone Number',
      id: 'phone',
      name: 'phone',
      type: 'tel',
      register: register('phone', {
        required: 'phone is required',
      }),
      errorMsg: errors?.email?.message,
    },
    //Add Company name
    {
      label: 'Company Name',
      id: 'companyName',
      name: 'companyName',
      type: 'text',
      register: register('companyName'),
      required: false,
    },

    {
      label: 'Website',
      id: 'website',
      name: 'website',
      type: 'text',
      register: register('website'),
      required: false,
    },
  ]
  return (
    <div
      className={cn(
        ' custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/customers'}>All Customers</NavLink>
      </Button>
      {loading ? (
        <div className="flex w-full items-center justify-center">
          <Loader />
        </div>
      ) : error?.message ? (
        <p>Error</p>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
        >
          {fields.map((field) => (
            <Input key={field.id} {...field} />
          ))}
          <div className="flex flex-col items-center gap-3">
            <div className="flex flex-col items-center gap-2 md:flex-row">
              <ButtonSubmit disabled={!isValid} type="submit">
                Save Customer Account
              </ButtonSubmit>
              <Button
                onClick={() =>
                  reset({
                    email: '',
                    phone: '',
                    name: '',
                    companyName: '',
                    website: '',
                  })
                }
              >
                Clear Details
              </Button>
            </div>
          </div>
        </form>
      )}
      <div className="flex w-full items-center justify-center">
        <DeleteButton onClick={() => setOpen(true)}>
          <DeleteIcon />
          Delete Customer
        </DeleteButton>
      </div>
      {open && (
        <ModalContainer>
          <Modal>
            <div className="flex h-full w-full flex-col rounded-lg bg-background p-6">
              <p className="text-lg font-medium">Are you sure?</p>
              <p className="text-sm text-muted-foreground">
                Do you really want to delete this customer? This process cannot
                be undone.
              </p>
              <div className="mt-4 flex gap-4">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <DeleteButton onClick={handleDelete}>
                  <DeleteIcon />
                  Delete Customer
                </DeleteButton>
              </div>
            </div>
          </Modal>
          <Overlay onClick={() => setOpen(false)} />
        </ModalContainer>
      )}
    </div>
  )
}

export default EditCustomer
