import React, { useEffect } from 'react'
import { cn } from '../../utils/helperFunctions'
import { NavList } from './NavList'
import { useSelector, useDispatch } from 'react-redux'
import { closeSidebar } from '../../redux/slices/sidebarSlice'
import useMobile from '../../hooks/useMobile'
import { useLocation } from 'react-router-dom'
const Sidebar = ({ className, ...props }) => {
  const { open } = useSelector((state) => state.sidebar)
  const { isMobile } = useMobile()
  const dispatch = useDispatch()
  const handleCloseSidebar = () => {
    dispatch(closeSidebar())
  }
  const { pathname } = useLocation()
  useEffect(() => {
    if (isMobile) dispatch(closeSidebar()) // Close Sidebar on route change
  }, [pathname])
  useEffect(() => {
    if (isMobile) dispatch(closeSidebar()) // Close Sidebar on mobile
  }, [isMobile])
  return (
    <aside
      className={cn(
        'absolute left-0 top-0 z-20 flex h-full w-full shrink-0 overflow-y-auto overflow-x-hidden md:static md:w-[350px]',
        className,
        {
          'translate-x-0': open,
          '-translate-x-full md:absolute': !open,
        },
      )}
      {...props}
    >
      <NavList />
      {/* Overlay */}
      <div
        onClick={handleCloseSidebar}
        className="h-full w-[100px] bg-foreground/50 md:hidden"
      ></div>
    </aside>
  )
}
export default Sidebar
