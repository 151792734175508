import React from 'react'
import { cn } from '../utils/helperFunctions'

const CaptionLayout = ({ children, results, caption, className, ...props }) => {
  return (
    <caption
      {...props}
      className={cn(
        'flex w-full flex-col items-start gap-2 sm:flex-row sm:items-center sm:justify-between',
        className,
      )}
    >
      {children}
      <p className="flex items-center gap-2">
        <span>{results}</span>
        {caption}
      </p>
    </caption>
  )
}

export default CaptionLayout
