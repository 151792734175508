import React, { useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'

const useFetch = ({ fn }) => {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const fetchData = useCallback(
    async (...args) => {
      try {
        setLoading(true)
        const response = await fn(...args)
        setData(response)
      } catch (error) {
        setError(error)

        if (error?.response?.data?.message) {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            localStorage.removeItem('token')
            return (window.location.href = '/login')
          }
          toast.error(error.response.data.message)
        } else {
          toast.error(error.message)
        }
      } finally {
        setLoading(false)
      }
    },
    [fn],
  )
  useEffect(() => {
    fetchData()
  }, [])
  return { data, loading, error, setData, refetch: fetchData }
}

export default useFetch
