import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '../../components/shared/Button'
import DeleteButton from '../../components/shared/DeleteButton'
import { DeleteIcon, EditIcon } from '../../components/shared/Icons'
import Record from '../../components/shared/Record'
import useFetch from '../../hooks/useFetch'
import ViewLayout from '../../layouts/ViewLayout'
import { deleteParentCategory, getParentCategory } from '../../utils/Apis/category'
import { formatDate } from '../../utils/formatDate'
import { withErrorHandling } from '../../utils/withErrorHandling'
import Overlay from '../../components/ModalContainer/Overlay'
import ModalContainer from '../../components/ModalContainer'
import Modal from '../../components/ModalContainer/Modal'

const ViewCategory = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, error, loading } = useFetch({ fn: () => getParentCategory(id) })
  const [open, setOpen] = React.useState(false)

  const handleDelete = withErrorHandling(async () => {
    setOpen(false) // Assuming setOpen is part of the component's state management
    const response = await deleteParentCategory(id)

    if (response?.message) {
      toast.success('Parent Category deleted successfully')
      setTimeout(() => {
        navigate('/parentCategory') // Correct usage inside setTimeout
      }, 2000)
    } else {
      toast.error('Failed to delete the Parent Category' || response.error) // Using the error message from the response if available
    }
  })
  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  return (
    <ViewLayout
      sectionTitle="Parent Category Details"
      allButtonTitle="All Parent Category"
      allButtonPath="/parentCategory"
      action={
        <div className="flex flex-col gap-2 md:flex-row">
          <Button>
            <EditIcon />
            <NavLink to={`/parentCategory/edit/${id}`}>
              Update Parent Category
            </NavLink>
          </Button>
          <DeleteButton onClick={() => setOpen(true)}>
            <DeleteIcon />
            Delete Parent Category
          </DeleteButton>
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        {data?.parentCategory?.imageUrl && (
          <img
            src={data?.parentCategory?.imageUrl}
            className="h-auto w-full rounded-xl md:w-[200px]"
            alt={data?.parentCategory?.name}
          />
        )}
        <h1 className="text-xl font-bold">{data?.parentCategory?.name}</h1>
      </div>
      <Record
        label="Created At"
        value={formatDate(data?.parentCategory?.createdAt)}
      />
      <NavLink
        className="text-primary"
        to={`/admin_users/view/${data?.parentCategory?.createdBy?._id}`}
      >
        {/* <Record
          label="Created By"
          value={data?.parentCategory?.createdBy?.name}
        /> */}
      </NavLink>
      {/* {data?.parentCategory?.updatedBy && (
        <Record
          label="Updated By"
          value={data?.parentCategory?.updatedBy?.name}
        />
      )} */}
      <Record label="Description" value={data?.parentCategory?.description} />
      {open && (
        <ModalContainer>
          <Modal>
            <div className="flex h-full w-full flex-col rounded-lg bg-background p-6">
              <p className="text-lg font-medium">Are you sure?</p>
              <p className="text-sm text-muted-foreground">
                Do you really want to delete this Parent Category? This process
                cannot be undone.
              </p>
              <div className="mt-4 flex gap-4">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <DeleteButton onClick={handleDelete}>
                  <DeleteIcon />
                  Delete ParentCategory
                </DeleteButton>
              </div>
            </div>
          </Modal>
          <Overlay onClick={() => setOpen(false)} />
        </ModalContainer>
      )}
    </ViewLayout>
  )
}

export default ViewCategory
