import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ModalContainer from '../../components/ModalContainer'
import Modal from '../../components/ModalContainer/Modal'
import Overlay from '../../components/ModalContainer/Overlay'
import Button from '../../components/shared/Button'
import DeleteButton from '../../components/shared/DeleteButton'
import { DeleteIcon, EditIcon } from '../../components/shared/Icons'
import Record from '../../components/shared/Record'
import useFetch from '../../hooks/useFetch'
import ViewLayout from '../../layouts/ViewLayout'
import {
  deleteShippingCharge,
  getShippingCharge,
} from '../../utils/Apis/shippingCharges'
import { formatDate } from '../../utils/formatDate'
import { isPrimitiveDataType } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'
const ViewShipping = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, error, loading } = useFetch({ fn: () => getShippingCharge(id) })
  const [open, setOpen] = React.useState(false)
  const handleDelete = withErrorHandling(async () => {
    setOpen(false)
    const response = await deleteShippingCharge(id)
    if (response?.message) {
      toast.success('Shipping deleted successfully')
      setTimeout(() => {
        navigate('/shipping_charges')
      }, 2000)
    }
  })
  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>
  return (
    <ViewLayout
      sectionTitle={'Shipping Details'}
      allButtonTitle={'All Shipping'}
      allButtonPath={'/shipping_charges'}
      action={
        <div className="flex flex-col gap-2 md:flex-row">
          <Button>
            <EditIcon />
            <NavLink to={`/shipping_charges/edit/${id}`}>
              Update Shipping
            </NavLink>
          </Button>
          <DeleteButton onClick={() => setOpen(true)}>
            <DeleteIcon />
            Delete Shipping
          </DeleteButton>
        </div>
      }
    >
      {data?.shippingCharge &&
        Object.keys(data?.shippingCharge).length > 0 &&
        Object.keys(data?.shippingCharge).map((key, index) => {
          if (
            key === 'createdBy' ||
            key === 'updatedAt' ||
            key === '__v' ||
            key === '_id' ||
            key === 'isFree'
          )
            return null

          return (
            <Record
              key={index}
              label={key}
              value={
                key === 'createdAt'
                  ? formatDate(data?.shippingCharge[key])
                  : isPrimitiveDataType(data?.shippingCharge[key])
                    ? data?.shippingCharge[key]
                    : null
              }
            />
          )
        })}
      <Record
        label="Is Free"
        value={data?.shippingCharge?.isFree ? 'Yes' : 'No'}
      />
      <NavLink
        className={'text-primary'}
        to={`/admin_users/view/${data?.shippingCharge?.createdBy?._id}`}
      >
        <Record
          label="Created By"
          value={data?.shippingCharge?.createdBy?.name}
        />
      </NavLink>
      {open && (
        <ModalContainer>
          <Modal>
            <div className="flex h-full w-full flex-col rounded-lg bg-background p-6">
              <p className="text-lg font-medium">Are you sure?</p>
              <p className="text-sm text-muted-foreground">
                Do you really want to delete this Shipping? This process cannot
                be undone.
              </p>
              <div className="mt-4 flex gap-4">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <DeleteButton onClick={handleDelete}>
                  <DeleteIcon />
                  Delete Shipping
                </DeleteButton>
              </div>
            </div>
          </Modal>
          <Overlay onClick={() => setOpen(false)} />
        </ModalContainer>
      )}
    </ViewLayout>
  )
}

export default ViewShipping
