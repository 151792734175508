import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import ReactSelect from 'react-select'
import countryList from 'react-select-country-list'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import SelectOption from '../../components/SelectOption'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import CheckBox from '../../components/shared/CheckBox'
import useFetch from '../../hooks/useFetch'
import {
  getShippingCharge,
  updateShippingCharge,
} from '../../utils/Apis/shippingCharges'
import { cn } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'

const EditShipping = ({ className, ...props }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm()
  const { data } = useFetch({ fn: () => getShippingCharge(id) })
  const [type, setType] = React.useState('Flat Rate')
  const [country, setCountry] = useState('')
  const onSubmit = withErrorHandling(async (data) => {
    const isFree = document.getElementById('isFree').checked
    const response = await updateShippingCharge({
      id,
      payload: {
        cartValue: data?.cartValue,
        country,
        name: data?.name,
        shippingCharge: data?.shippingCharge,
        type,
        isFree,
      },
    })
    if (response?.shippingCharge) {
      toast.success('Shipping updated successfully')
      setTimeout(() => {
        navigate('/shipping_charges')
      }, 2000)
    }
  })
  const fields = [
    {
      id: 'name',
      label: 'Name',
      type: 'text',
      name: 'name',
      register: register('name', {
        required: 'Name is required',
      }),
      errorMsg: errors?.name?.message,
      required: true,
    },
    {
      id: 'shippingCharge',
      label: 'Shipping Charge',
      type: 'number',
      name: 'shippingCharge',
      register: register('shippingCharge', {
        required: 'Shipping Charge is required',
      }),
      errorMsg: errors?.shippingCharge?.message,
      required: true,
    },
    {
      id: 'cartValue',
      label: 'Cart Value',
      type: 'number',
      name: 'cartValue',
      register: register('cartValue', {
        required: 'Cart Value is required',
      }),
      errorMsg: errors?.cartValue?.message,
      required: true,
    },
  ]
  useEffect(() => {
    if (data?.shippingCharge) {
      reset(data?.shippingCharge)
    }
  }, [data])
  const countryOptions = useMemo(() => countryList().getData(), [])
  return (
    <div
      className={cn(
        ' custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/shipping_charges'}>All Shipping</NavLink>
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
      >
        {fields.map((field) => (
          <Input key={field.id} {...field} />
        ))}
        <SelectOption
          label="Type"
          id="Type"
          name="Type"
          register={register('Type', {
            required: 'Type is required',
          })}
          errorMsg={errors?.type?.message}
          required={true}
          options={['Flat Rate', 'Country Wise']}
          onChange={(e) => setType(e.target.value)}
        />
        {type === 'Country Wise' && (
          <div className="flex w-full flex-col gap-2 lg:grid lg:grid-cols-4">
            <label className="font-semibold">
              Country<span className="text-destructive">*</span>:
            </label>
            <ReactSelect
              onChange={(e) => setCountry(e.label)}
              className="col-span-3"
              options={countryOptions}
            />
          </div>
        )}
        <div className="flex w-full items-start">
          <CheckBox
            label={'Is Free'}
            className={'checkbox'}
            register={register('isFree')}
            name={'checkbox'}
            id={'isFree'}
            checked={data?.shippingCharge?.isFree}
          />
        </div>
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <ButtonSubmit disabled={!isValid} type="submit">
              Update Shipping Charge
            </ButtonSubmit>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditShipping
