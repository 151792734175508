import { cn } from '../utils/helperFunctions'

const SelectOption = ({
  id,
  name,
  register,
  label,
  required,
  errorMsg,
  className,
  options,
  onChange,
  value,
  ...props
}) => {
  return (
    <div className="flex w-full flex-col items-start gap-2 lg:items-end">
      <fieldset className="flex w-full flex-col items-start gap-2 lg:grid lg:grid-cols-4 lg:items-center lg:gap-3">
        <label className="col-span-1 h-full font-semibold" htmlFor={id}>
          {label}
          {required && <span className="text-destructive">*</span>}:
        </label>
        <div className="col-span-3 flex w-full flex-col gap-2">
          <select
            className={cn(
              'select-scroll h-9 w-full rounded-md border px-2 py-1 outline-none focus:ring-2',
              className,
            )}
            id={id}
            name={name}
            {...register}
            onChange={onChange}
            value={value}
            {...props}
          >
            {options.map((option) => (
              <option
                key={option.value || option}
                value={option.value || option}
              >
                {option.name || option}
              </option>
            ))}
          </select>
          {errorMsg && <p className="text-destructive">{errorMsg}</p>}
        </div>
      </fieldset>
    </div>
  )
}

export default SelectOption
