const Image = ({ src, alt = '', loading = 'lazy', className, ...props }) => {
  return (
    <img
      src={src}
      alt={alt}
      loading={loading}
      className={className}
      {...props}
    />
  )
}

export default Image
