import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import ModalContainer from '../../components/ModalContainer'
import Modal from '../../components/ModalContainer/Modal'
import Overlay from '../../components/ModalContainer/Overlay'
import SelectOption from '../../components/SelectOption'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import DeleteButton from '../../components/shared/DeleteButton'
import { DeleteIcon } from '../../components/shared/Icons'
import Loader from '../../components/shared/Loader'
import useFetch from '../../hooks/useFetch'
import { deleteAdmin, getAdmin, updateAdmin } from '../../utils/Apis/admins'
import { cn } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'
import { updateMe } from '../../utils/Apis/me'

const EditAdmin = ({ className, ...props }) => {
  const { id } = useParams()
  const { data, error, loading } = useFetch({
    fn: () => getAdmin(id),
  })
  const navigate = useNavigate()
  const admin = data?.user
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onSubmit',
  })
  const [open, setOpen] = useState(false)

  const handleDelete = withErrorHandling(async () => {
    const response = await deleteAdmin(id)
    if (response?.success) {
      toast.success('User Deleted Successfully')
      setTimeout(() => {
        navigate(`/admin_users/view/${id}`)
      }, 2000)
    }
  })
  const onSubmit = withErrorHandling(async (data) => {
    let response
    if (id === localStorage.getItem('id')) {
      response = await updateMe(data)
    } else {
      response = await updateAdmin({
        id,
        payload: {
          ...data,
        },
      })
    }
    if (response?.user) {
      toast.success('Admin Updated Successfully')
      setTimeout(() => {
        navigate('/admin_users')
      }, 2000)
    }
  })

  useEffect(() => {
    if (admin) {
      reset({
        ...data?.user,
      })
    }
  }, [admin]) 
  const fields = [
    {
      label: 'Name',
      id: 'name',
      name: 'name',
      register: register('name', {
        required: 'Name is required',
      }),
      errorMsg: errors?.name?.message,
      required: true,
    },
    {
      label: 'Email',
      id: 'email',
      name: 'email',
      type: 'email',
      register: register('email', {
        required: 'Email is required',
      }),
      errorMsg: errors?.email?.message,
    },
    {
      label: 'Phone Number',
      id: 'phone',
      name: 'phone',
      type: 'tel',
      register: register('phone', {
        required: 'phone is required',
      }),
      errorMsg: errors?.email?.message,
    },
  ]
  return (
    <div
      className={cn(
        ' custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/admin_users'}>All Admins</NavLink>
      </Button>
      {loading ? (
        <div className="flex w-full items-center justify-center">
          <Loader />
        </div>
      ) : error?.message ? (
        <p>Error</p>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
        >
          {fields.map((field) => (
            <Input key={field.id} {...field} />
          ))}
          <SelectOption
            label="role"
            id="role"
            name="role"
            register={register('role', {
              required: 'role is required',
            })}
            errorMsg={errors?.role?.message}
            required={true}
            options={['user', 'admin']}
          />
          <div className="flex flex-col items-center gap-3">
            <div className="flex flex-col items-center gap-2 md:flex-row">
              <ButtonSubmit disabled={!isValid} type="submit">
                Save Admin
              </ButtonSubmit>
              <Button
                onClick={() =>
                  reset({
                    email: '',
                    phone: '',
                    name: '',
                  })
                }
              >
                Clear Details
              </Button>
            </div>
          </div>
        </form>
      )}
      <div className="flex w-full items-center justify-center">
        <DeleteButton onClick={() => setOpen(true)}>
          <DeleteIcon />
          Delete Admin
        </DeleteButton>
      </div>
      {open && (
        <ModalContainer>
          <Modal>
            <div className="flex h-full w-full flex-col rounded-lg bg-background p-6">
              <p className="text-lg font-medium">Are you sure?</p>
              <p className="text-sm text-muted-foreground">
                Do you really want to delete this Admin? This process cannot be
                undone.
              </p>
              <div className="mt-4 flex gap-4">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <DeleteButton onClick={handleDelete}>
                  <DeleteIcon />
                  Delete Admin
                </DeleteButton>
              </div>
            </div>
          </Modal>
          <Overlay onClick={() => setOpen(false)} />
        </ModalContainer>
      )}
    </div>
  )
}

export default EditAdmin
