import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '../components/shared/Button'
import { EditIcon } from '../components/shared/Icons'
import Loader from '../components/shared/Loader'
import Record from '../components/shared/Record'
import useFetch from '../hooks/useFetch'
import ViewLayout from '../layouts/ViewLayout'
import { logout } from '../utils/Apis/auth'
import {
  getProfileDetails,
  updatePassword,
  uploadAvatar,
} from '../utils/Apis/me'

import { formatDate } from '../utils/formatDate'
import { isPrimitiveDataType } from '../utils/helperFunctions'
import { withErrorHandling } from '../utils/withErrorHandling'
import Image from '../components/shared/Image'
const Profile = ({ ...props }) => {
  const { data, loading, error } = useFetch({ fn: () => getProfileDetails() })
  const [open, setOpen] = React.useState(false)
  const [avatar, setAvatar] = React.useState(null)
  const [loadingAvatar, setLoadingAvatar] = React.useState(false)
  const oldPassword = React.useRef()
  const password = React.useRef()
  const navigate = useNavigate()
  const handleUpdatePassword = withErrorHandling(async (e) => {
    e.preventDefault()
    const response = await updatePassword({
      oldPassword: oldPassword.current.value,
      password: password.current.value,
    })
    if (response?.success) {
      toast.success('Password updated successfully')
      localStorage.removeItem('token')
      await logout()
      navigate('/login')
      setOpen(false)
    }
  })

  const handleUploadAvatar = withErrorHandling(async (e) => {
    e.preventDefault()
    try {
      setLoadingAvatar(true)
      const response = await uploadAvatar({ avatar })
      if (response?.user) {
        toast.success('Avatar updated successfully')
        setAvatar(response.user.avatar.url)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    } catch (error) {
      if (error.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Something went wrong')
      }
    } finally {
      setLoadingAvatar(false)
    }
  })
  const handleChange = (e) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0])
  }

  useEffect(() => {
    document.title = 'Profile - Admin'
  }, [])

  return (
    <ViewLayout
      sectionTitle={'Profile Details'}
      action={
        <Button onClick={() => setOpen(true)}>
          <EditIcon />
          Update Password
        </Button>
      }
      {...props}
    >
      {loading ? (
        <div className="flex w-full items-center justify-center">
          <Loader />
        </div>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <div className="flex w-full flex-col items-center gap-2 md:w-fit">
              <div className="relative size-[150px] md:size-[200px]">
                <Image
                  className={
                    'absolute left-0 top-0 h-full w-full rounded-full object-cover object-center'
                  }
                  src={
                    avatar
                      ? avatar
                      : data?.user?.avatar?.url
                        ? data?.user?.avatar?.url
                        : '/img/profile.webp' // Default Image
                  }
                  alt={data?.user?.name}
                />
                {loadingAvatar && (
                  <div className="absolute left-0 top-0 h-full w-full animate-pulse rounded-full bg-background/50"></div>
                )}
                <label
                  htmlFor="upload-image"
                  className="absolute bottom-0 right-0 cursor-pointer rounded-full bg-primary p-2 text-primary-foreground"
                >
                  <EditIcon />
                </label>
                <input
                  type="file"
                  onChange={handleChange}
                  id="upload-image"
                  className="hidden"
                />
              </div>
              {avatar && (
                <div className="flex flex-col gap-2 md:flex-row">
                  <Button
                    onClick={handleUploadAvatar}
                    disabled={loadingAvatar}
                    className={
                      'disabled:bg-muted disabled:text-muted-foreground disabled:hover:bg-muted disabled:hover:text-muted-foreground'
                    }
                  >
                    Upload
                  </Button>
                  <Button
                    onClick={() => setAvatar(null)}
                    disabled={loadingAvatar}
                    className={
                      'bg-muted text-muted-foreground hover:bg-muted hover:text-muted-foreground'
                    }
                  >
                    Cancel
                  </Button>
                </div>
              )}
              <h1 className="text-2xl font-bold">{data?.user?.name}</h1>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {data?.user &&
              Object.keys(data?.user).length > 0 &&
              Object.keys(data?.user).map((key, index) => {
                if (
                  key === 'createdBy' ||
                  key === '__v' ||
                  key === '_id' ||
                  key === 'name' ||
                  key === 'role' ||
                  !isPrimitiveDataType(data?.user[key])
                )
                  return null
                return (
                  <Record
                    key={index}
                    label={key}
                    value={
                      key === 'createdAt' || key === 'updatedAt'
                        ? formatDate(data?.user[key])
                        : data?.user[key]
                    }
                  />
                )
              })}

            {data?.user?.createdBy &&
              isPrimitiveDataType(data?.user?.createdBy) && (
                <Link
                  className="text-primary"
                  to={`/admin_users/view/${data?.user?.createdBy?._id}`}
                >
                  <Record
                    label={'Created By'}
                    value={data?.user?.createdBy?.name}
                  />
                </Link>
              )}
          </div>
        </>
      )}

      {/* Modal */}
      {open && (
        <div className="fixed left-0 top-0 h-full w-full">
          <form
            onSubmit={handleUpdatePassword}
            className="absolute left-[50%] top-[50%] z-40 flex w-[60%] translate-x-[-50%] translate-y-[-50%] flex-col items-center justify-center gap-4 rounded-lg bg-white px-5 py-6 md:w-[400px]"
          >
            <input
              name="oldPassword"
              type="text"
              className="w-full rounded-lg border p-3"
              placeholder="Old Password"
              required
              ref={oldPassword}
            />
            <input
              name="password"
              type="text"
              className="w-full rounded-lg border p-3"
              placeholder="New Password"
              required
              ref={password}
              minLength={6}
            />
            <Button type="submit">Update Password</Button>
          </form>
          {/* Overlay */}
          <div
            onClick={() => setOpen(false)}
            className="absolute z-10 h-full w-full bg-foreground/30"
          ></div>
        </div>
      )}
    </ViewLayout>
  )
}
export default Profile
