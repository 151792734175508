import instance from './axios-instance'

export const getAllSubscriptions = async () => {
  const { data } = await instance.get('/api/v1/admin/subscription')
  return data
}

export const getSubscriptionById = async (id) => {
  const { data } = await instance.get(`/api/v1/admin/subscription/${id}`)
  return data?.subscription
}

export const updateSubscription = async ({ id, payload }) => {
  const { data } = await instance.put(
    `/api/v1/admin/subscription/${id}`,
    payload,
  )
  return data
}

export const deleteSubscription = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/subscription/${id}`)
  return data
}
