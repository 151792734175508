import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import store from './redux/store'
import { ToastContainer } from 'react-toastify'
import {
  AdminUsers,
  Attributes,
  Categories,
  Customers,
  Dashboard,
  EditAdmin,
  EditAttribute,
  EditCategory,
  EditCustomer,
  EditParentCategory,
  EditProduct,
  EditShipping,
  Login,
  NewAdmin,
  NewAttribute,
  NewCategory,
  NewParentCategory,
  NewProduct,
  NewShipping,
  Newsletter,
  Orders,
  Products,
  Profile,
  ShippingCharges,
  ViewAdmin,
  ViewAttribute,
  ViewCategory,
  ViewCustomer,
  ViewOrder,
  ViewParentCategory,
  ViewProduct,
  ViewShipping,
} from './screens'
import 'react-toastify/dist/ReactToastify.css'
import ErrorBoundary from './components/ErrorBoundary'
import PrivateRoutes from './components/PrivateRoutes'
import RootLayout from './layouts/RootLayout'
import PageLayout from './layouts/PageLayout'
import ParentCategory from './screens/ParentCategory'
const App = () => {
  return (
    <ErrorBoundary fallback={<div>Some thing wrong</div>}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoutes>
                  <RootLayout />
                </PrivateRoutes>
              }
            >
              <Route path="/" element={<PageLayout />}>
                <Route index={true} path="/" element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="customers">
                  <Route index={true} element={<Customers />} />
                  <Route path="edit/:id" element={<EditCustomer />} />
                  <Route path="view/:id" element={<ViewCustomer />} />
                </Route>
                <Route path="orders">
                  <Route index={true} element={<Orders />} />
                  <Route path="view/:id" element={<ViewOrder />} />
                </Route>
                <Route path="newsletter" element={<Newsletter />} />
                <Route path="categories">
                  <Route index={true} element={<Categories />} />
                  <Route path="new" element={<NewCategory />} />
                  <Route path="edit/:id" element={<EditCategory />} />
                  <Route path="view/:id" element={<ViewCategory />} />
                </Route>
                <Route path="parentCategory">
                  <Route index={true} element={<ParentCategory />} />
                  <Route path="new" element={<NewParentCategory />} />
                  <Route path="edit/:id" element={<EditParentCategory />} />
                  <Route path="view/:id" element={<ViewParentCategory />} />
                </Route>
                <Route path="attributes">
                  <Route index={true} element={<Attributes />} />
                  <Route path="new" element={<NewAttribute />} />
                  <Route path="edit/:id" element={<EditAttribute />} />
                  <Route path="view/:id" element={<ViewAttribute />} />
                </Route>
                <Route path="products">
                  <Route index={true} element={<Products />} />
                  <Route path="new" element={<NewProduct />} />
                  <Route path="edit/:id" element={<EditProduct />} />
                  <Route path="view/:id" element={<ViewProduct />} />
                </Route>
                <Route path="shipping_charges">
                  <Route index={true} element={<ShippingCharges />} />
                  <Route path="new" element={<NewShipping />} />
                  <Route path="edit/:id" element={<EditShipping />} />
                  <Route path="view/:id" element={<ViewShipping />} />
                </Route>
                <Route path="admin_users">
                  <Route index={true} element={<AdminUsers />} />
                  <Route path="new" element={<NewAdmin />} />
                  <Route path="edit/:id" element={<EditAdmin />} />
                  <Route path="view/:id" element={<ViewAdmin />} />
                </Route>
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<div>Not Found</div>} />
              </Route>
            </Route>
            <Route path="login" element={<Login />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer autoClose={2000} />
      </Provider>
    </ErrorBoundary>
  )
}

export default App
