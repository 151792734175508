import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import CheckBox from '../../components/shared/CheckBox'
import useFetch from '../../hooks/useFetch'
import { createAttribute } from '../../utils/Apis/attributes'
import { getAllparentCategory } from '../../utils/Apis/category'
import { cn } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'

const NewAttributes = ({ className, ...props }) => {
  const [searchParam] = useSearchParams()
  const navigate = useNavigate()
  const { data, loading } = useFetch({ fn: getAllparentCategory })
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm()
  const onSubmit = withErrorHandling(async (data) => {
    const checkboxes = document.querySelectorAll('.checkboxes')
    const checked = Array.from(checkboxes).filter(
      (checkbox) => checkbox.checked,
    )
    const checkedValues = checked.map((checkbox) => checkbox.value)
    if (checkedValues.length === 0) {
      return toast.error('Please select at least one category')
    }
    const response = await createAttribute({
      name: data.name,
      parentCategories: checkedValues,
    })
    if (response?.arrtibute) {
      toast.success('Attributes created successfully')
      setTimeout(() => {
        navigate(`/attributes/view/${response.arrtibute._id}`)
      }, 2000)
    }
  })
  const checkAll = (e) => {
    const checkboxes = document.querySelectorAll('.checkboxes')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = e.target.checked
    })
  }
  useEffect(() => {
    if (searchParam.get('category') && data) {
      const checkboxes = document.querySelectorAll('.checkboxes')
      checkboxes.forEach((checkbox) => {
        if (searchParam.get('category').includes(checkbox.value)) {
          checkbox.checked = true
        }
      })
    }
  }, [searchParam, data])
  return (
    <div
      className={cn(
        ' custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/attributes'}>All Attributes</NavLink>
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
      >
        <Input
          label="Name"
          id="name"
          name="name"
          register={register('name', {
            required: 'Name is required',
          })}
          errorMsg={errors?.name?.message}
          required={true}
        />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="flex w-full flex-col items-start gap-6 md:flex-row">
            <div className="flex items-center gap-2">
              <input
                id="checkbox"
                type="checkbox"
                className="size-5"
                onChange={checkAll}
              />
              <label htmlFor="checkbox">Categories Applicable:</label>
            </div>
            <ul className="flex flex-col gap-3">
              {data?.parentCategories?.map(
                (category, index) => (
                  <CheckBox
                    key={index}
                    label={category?.name}
                    id={category?._id}
                    name={category?.name}
                    value={category?._id}
                  />
                ),
              )}
            </ul>
          </div>
        )}
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <ButtonSubmit disabled={!isValid} type="submit">
              Add Attributes
            </ButtonSubmit>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewAttributes
