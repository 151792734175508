import { TableData } from './TableData'
import { cn } from '../../utils/helperFunctions'
import { TableRow } from './TableRow'
import { TableHeader } from './TableHeader'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useEffect } from 'react'
export const tableCellClasses = 'pr-6 max-w-[300px] text-left py-3'
const Table = ({
  className,
  columns,
  data = [],
  setTable = () => {},
  classNameForHeader,
  classNameForData,
  ...props
}) => {
  const table = useReactTable({
    data,
    columns,
    state: {
      pagination: {
        pageSize: 20,
        pageIndex: 0,
      },
    },
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  })
  useEffect(() => {
    setTable(table)
  }, [setTable, table])

  return (
    <table className={cn('w-full', className)} {...props}>
      <thead className={cn('sticky top-0 bg-background')}>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeader
                className={classNameForHeader}
                key={header.id}
                colSpan={header.colSpan}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableData className={classNameForData} key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableData>
            ))}
          </TableRow>
        ))}
      </tbody>
      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.footer,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </table>
  )
}
export default Table
