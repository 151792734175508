import instance from './axios-instance'

export const getAdmins = async (keyword = '', page = 1) => {
  let { data } = await instance.get(
    `/api/v1/admin/admins?keyword=${keyword}&page=${page}`,
  )
  return data
}

export const updatePassword = async ({ oldPassword, password }) => {
  const { data } = await instance.put('/api/v1/password/update', {
    oldPassword,
    password,
  })
  return data
}
export const updateAdmin = async ({ id, payload }) => {
  const { data } = await instance.put(`/api/v1/admin/users/${id}`, payload)
  return data
}
export const deleteAdmin = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/users/${id}`)
  return data
}
export const getAdmin = async (id) => {
  const { data } = await instance.get(`/api/v1/admin/users/${id}`)
  return data
}
export const addAdmin = async ({ email, password, role, name, phone }) => {
  const { data } = await instance.post('/api/v1/admin/users', {
    email,
    password,
    role,
    name,
    phone,
  })
  return data
}
