import React from 'react'
import Button from './shared/Button'
import { AddIcon, MinusIcon } from './shared/Icons'
const BulkPricing = ({ number = 1 }) => {
  const [numberOfBulkPricing, setNumberOfBulkPricing] = React.useState(
    number || 1,
  )
  const increaseBulkPricing = () => {
    setNumberOfBulkPricing((prev) => prev + 1)
  }
  const decreaseBulkPricing = () => {
    setNumberOfBulkPricing((prev) => Math.max(prev - 1, 1))
  }
  return (
    <div className="flex w-full flex-col gap-3">
      <h1 className="font-bold">Bulk Pricing:</h1>
      <table className="w-full md:w-[50%]">
        <thead>
          <tr>
            <th className="text-center">Qty</th>
            <th className="text-center">Unit Price</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: numberOfBulkPricing }).map((item, index) => (
            <tr key={index} className="bulk_pricing">
              {Array.from({ length: 2 }).map((_, index) => (
                <td key={index} className="px-3">
                  <input
                    type={index === 0 ? 'number' : 'text'}
                    className="w-full rounded-lg border text-center"
                    min={1}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex w-full items-center justify-center gap-4 md:w-[50%]">
        <Button className={'rounded-full'} onClick={increaseBulkPricing}>
          <AddIcon />
        </Button>
        <Button className={'rounded-full'} onClick={decreaseBulkPricing}>
          <MinusIcon />
        </Button>
      </div>
    </div>
  )
}

export default BulkPricing
