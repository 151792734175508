import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { cn } from '../../utils/helperFunctions'
import { BackIcon } from './Icons'
import Button from './Button'
const Pagination = ({
  pages,
  currentPage,
  setCurrentPage,
  table,
  className,
  showGoToPage = false,
  ...props
}) => {
  const { search } = useLocation()
  const [goToPageState, setGoToPageState] = useState(1)
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    setCurrentPage(Number(searchParams.get('page')) || 1)
  }, [search, setCurrentPage])
  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pages))
  }
  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }
  const goToPage = (page) => {
    setCurrentPage((prev) => Math.min(Math.max(page, 1), pages))
  }
  const handleOnChange = (e) => {
    setGoToPageState(e.target.value)
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', currentPage)
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}?${searchParams}`,
    )
  }, [currentPage])
  return (
    <div className="flex flex-col gap-2">
      <div className={cn('flex items-center gap-4', className)} {...props}>
        <button onClick={prevPage}>
          <BackIcon />
        </button>
        <ul className="flex items-center gap-4">
          {pages > 6 ? (
            <>
              {Array.from({ length: 5 }).map((_, i) => (
                <Item key={i} isCurrentPage={currentPage == i + 1} page={i + 1}>
                  {i + 1}
                </Item>
              ))}
              <li>...</li>
              <Item isCurrentPage={currentPage == pages} page={pages}>
                {pages}
              </Item>
            </>
          ) : (
            <>
              {Array.from({ length: pages }).map((_, i) => (
                <Item key={i} isCurrentPage={currentPage == i + 1} page={i + 1}>
                  {i + 1}
                </Item>
              ))}
            </>
          )}
        </ul>
        <button onClick={nextPage}>
          <BackIcon className="rotate-180" />
        </button>
      </div>
      {pages > 7 && (
        <div className="flex items-center gap-2">
          <input
            type="text"
            onChange={handleOnChange}
            placeholder="Go to page"
            className="rounded-lg border px-2 py-1"
          />
          <Button onClick={() => goToPage(goToPageState)} className={'py-1'}>
            Go
          </Button>
        </div>
      )}
    </div>
  )
}

const Item = ({ children, page, isCurrentPage }) => {
  return (
    <li>
      <NavLink
        className={cn('text-primary', {
          'text-foreground': isCurrentPage,
        })}
        to={`?page=${page}`}
      >
        {children}
      </NavLink>
    </li>
  )
}

export default Pagination
