import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import BulkPricing from '../../components/BulkPricing'
import Input from '../../components/Input'
import SelectOption from '../../components/SelectOption'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import CheckBox from '../../components/shared/CheckBox'
import useFetch from '../../hooks/useFetch'
import { createProduct } from '../../utils/Apis/products'
import {
  getAllSubCategories,
  getSubCategory,
} from '../../utils/Apis/subCategory'
import {
  cn,
  convertArrayToObject,
  convertToTitle,
  isObject,
} from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'
const fields = [
  'name',
  'Manufacturer',
  'CTYEA_Part_Number',
  'Manufacturer_Part_Number',
  'price',
  'description',
  'numOfReviews',
  'ratings',
  'stock',
  'Weight',
].map((item) => ({
  name: item,
  required: ![
    'description',
    'numOfReviews',
    'ratings',
    'CTYEA_Part_Number',
  ].includes(item),
}))
const NewProduct = ({ className, ...props }) => {
  const { data: categories } = useFetch({
    fn: () => getAllSubCategories('', 1, 10000),
  })
  const navigate = useNavigate()
  const [images, setImages] = useState(null)
  const [dataSheet, setDataSheet] = useState(null)
  const [subCategory, setSubCategory] = React.useState(null)
  const [selectedCategory, setSelectedCategory] = React.useState('')
  const [loadingSubCategory, setLoadingSubCategory] = React.useState(false)
  const [parentCategory, setParentCategory] = React.useState('')
  const [attributes, setAttributes] = React.useState([])
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  })
  const fetchingSubCategory = withErrorHandling(async (id) => {
    try {
      setLoadingSubCategory(true)
      const res = await getSubCategory(id)
      setSubCategory(res?.category)
      setLoadingSubCategory(false)
    } catch (error) {
      setLoadingSubCategory(false)
    }
  })

  const onSubmit = withErrorHandling(async (data) => {
    const bulkPricing = document.querySelectorAll('.bulk_pricing')
    let bulkPricingData = Array.from(bulkPricing).map((item) => {
      const [qty, unitPrice] = item.querySelectorAll('.bulk_pricing input')
      return {
        Qty: qty.value,
        UnitPrice: unitPrice.value,
      }
    })
    bulkPricingData = bulkPricingData.filter(
      (item) => item.Qty && item.UnitPrice,
    )

    let attributes = document.querySelectorAll('.attributes')
    attributes = Array.from(attributes).map((attr) => {
      const input = attr.querySelector('.attributes input')
      const key = attr.querySelector('label').innerText
      const value = input.value
      return { key, value }
    })

    attributes = convertArrayToObject(attributes)
    const formData = new FormData()
    if (images?.length > 0) {
      Object.keys(images).forEach((key) => {
        formData.append('image', images[key])
      })
    }
    if (dataSheet) {
      formData.append('datasheet', dataSheet)
    }

    if (isObject(attributes)) {
      Object.keys(attributes).forEach((key) => {
        formData.append(`product_Attributes.${key}`, attributes[key])
      })
    }
    formData.append('sup_category', subCategory?.name || subCategory)
    formData.append('category', parentCategory)
    bulkPricingData.forEach((item, i) => {
      formData.append(`bulk_Pricing[${i}][Qty]`, item.Qty)
      formData.append(`bulk_Pricing[${i}][UnitPrice]`, item.UnitPrice)
    })
    Object.keys(data).forEach((key) => {
      if (key !== 'sup_category') {
        formData.append(key, data[key])
      }
    })
    const response = await createProduct(formData)
    if (response?.product) {
      toast.success('Product created successfully')
      setTimeout(() => {
        navigate(`/products/view/${response?.product._id}`)
      }, 2000)
    }
  })
  const handleOnImageChange = (e) => {
    //Set file to the state
    if (e.target.files.length > 0) setImages(e.target.files)
  }
  const handleDataSheetChange = (e) => {
    if (e.target.files.length > 0) setDataSheet(e.target.files[0])
  }
  useEffect(() => {
    if (categories?.data?.length > 0) {
      setSelectedCategory(categories?.data[0])
    }
  }, [categories])
  useEffect(() => {
    if (selectedCategory && categories?.data) {
      fetchingSubCategory(selectedCategory)
    }
  }, [selectedCategory]) // eslint-disable-line
  useEffect(() => {
    if (subCategory) {
      setParentCategory(subCategory?.parentCategory?.name)
      setSelectedCategory(subCategory?.name)
      setAttributes(subCategory?.attributes || [])
    }
  }, [subCategory])
  return (
    <div
      className={cn(
        ' custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/products'}>All Products</NavLink>
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
      >
        {fields.map((field, index) => {
          return (
            <Input
              key={field.name}
              register={register(field.name, {
                required: field.required,
              })}
              errorMsg={
                Object.keys(errors).includes(field.name)
                  ? errors[field.name].message
                  : ''
              }
              label={convertToTitle(field.name)}
              required={field.required}
              className="w-full"
            />
          )
        })}
        <SelectOption
          label={convertToTitle('manufacturerLeadTimeUnit')}
          register={register('manufacturerLeadTimeUnit')}
          options={[
            { name: 'Days', value: 'Days' },
            { name: 'Weeks', value: 'Weeks' },
            { name: 'Months', value: 'Months' },
            { name: 'Years', value: 'Years' },
          ]}
        />
        <Input
          label={'Image(You can upload multiple images)'}
          onChange={handleOnImageChange}
          type="file"
          multiple
          required={false}
        />
        <Input
          label={'Data Sheet(PDF)'}
          onChange={handleDataSheetChange}
          type="file"
          accept=".pdf"
          required={false}
        />

        {categories?.data && categories?.data?.length > 0 && (
          <SelectOption
            label="Category"
            options={categories?.data?.map((category) => ({
              value: category._id,
              name: category.name,
            }))}
            register={register('sup_category')}
            onChange={(e) => setSelectedCategory(e.target.value)}
          />
        )}
        <div className="flex w-full items-start">
          <CheckBox
            register={register('IsQuote')}
            label="Is Quote"
            id={'IsQuote'}
          />
        </div>
        {loadingSubCategory ? (
          <p>Loading Attributes....</p>
        ) : (
          <div className="flex w-full flex-col items-start gap-3">
            <h3 className="font-bold">Product Attributes</h3>
            <ul className="flex w-full flex-col gap-3 lg:w-[40%]">
              {attributes?.map((attr, index) => {
                return (
                  <li
                    key={index}
                    className="attributes flex w-full flex-col items-start gap-2"
                  >
                    <label htmlFor={attr?.name}>{attr.name}</label>
                    <input
                      id={attr?.name}
                      type="text"
                      className="w-full rounded-lg border p-2"
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        <BulkPricing />
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <ButtonSubmit disabled={!isValid} type="submit">
              Add Product
            </ButtonSubmit>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewProduct
