import React from 'react'
import { cn } from '../utils/helperFunctions'
import { useSelector } from 'react-redux'
const MainContainer = ({ children, className, ...props }) => {
  const { open } = useSelector((state) => state.sidebar)
  return (
    <main
      className={cn(
        'flex w-full shrink-0 flex-col items-center bg-accent px-4 py-2 pt-12 md:flex-1 md:justify-start md:overflow-x-hidden md:pl-0 md:pt-8',
        {
          'md:px-8': open,
        },
        className,
      )}
      {...props}
    >
      <div className="flex w-full justify-center overflow-auto">{children}</div>
    </main>
  )
}

export default MainContainer
