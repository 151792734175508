import { cn } from '../utils/helperFunctions'

const Input = ({
  label,
  id,
  name,
  type = 'text',
  required = true,
  className,
  register,
  errorMsg,
  onChange,
  ...props
}) => {
  return (
    <div className="flex w-full flex-col items-start gap-2 lg:items-end">
      <fieldset className="flex w-full flex-col items-start gap-2 lg:grid lg:grid-cols-4 lg:items-center lg:gap-16">
        <label
          className="col-span-1 flex h-full w-full items-center font-semibold"
          htmlFor={id}
        >
          {label}
          {required && <span className="text-destructive">*</span>}:
        </label>
        <div className="col-span-3 flex w-full flex-col gap-2">
          <input
            className={cn(
              'h-9 w-full rounded-md border px-2 py-1 outline-none focus:ring-2',
              className,
            )}
            type={type}
            id={id}
            name={name}
            onChange={onChange}
            {...register}
            {...props}
          />
          {errorMsg && <p className="text-destructive">{errorMsg}</p>}
        </div>
      </fieldset>
    </div>
  )
}
export default Input
