import React from 'react'
import { NavLink } from 'react-router-dom'
import Image from '../shared/Image'
import { cn } from '../../utils/helperFunctions'

export const Item = ({
  path,
  title,
  icon,
  activeIcon,
  isActive = false,
  onClick,
  type = 'link',
  ...props
}) => {
  return (
    <li className="group w-fit" onClick={onClick} {...props}>
      {type === 'link' ? (
        <NavLink to={path} className="flex items-center gap-2">
          <Image
            src={isActive ? activeIcon : icon}
            alt={title}
            className="h-auto w-[24px]"
          />
          <span
            className={cn(
              'font-bold capitalize group-hover:text-popover-foreground',
              {
                'text-popover-foreground': isActive,
              },
            )}
          >
            {title}
          </span>
        </NavLink>
      ) : (
        <button className="flex items-center gap-2">
          <Image src={icon} alt={title} className="h-auto w-[24px]" />
          <span
            className={cn(
              'font-bold capitalize group-hover:text-popover-foreground',
            )}
          >
            {title}
          </span>
        </button>
      )}
    </li>
  )
}
