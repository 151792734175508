import React from 'react'

const Record = ({ label, value }) => {
  return (
    <section className="flex w-full break-before-all items-start gap-4 break-words">
      <p className="text-nowrap font-semibold capitalize">{label}:</p>
      <p className="w-[70%]">{value}</p>
    </section>
  )
}

export default Record
