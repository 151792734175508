import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import ActionBar from '../../components/ActionBar'
import Search from '../../components/Search'
import Button from '../../components/shared/Button'
import { AddIcon } from '../../components/shared/Icons'
import Loader from '../../components/shared/Loader'
import Pagination from '../../components/shared/Pagination'
import Table from '../../components/Table'
import useFetch from '../../hooks/useFetch'
import CaptionLayout from '../../layouts/CaptionLayout'
import Section from '../../layouts/Section'
import SectionBody from '../../layouts/SectionBody'
import { getAllSubCategories } from '../../utils/Apis/subCategory'
import { cn } from '../../utils/helperFunctions'
import { columns } from './COLUMNS'
import { withErrorHandling } from '../../utils/withErrorHandling'

const Categories = ({ className, ...props }) => {
  const [currentPage, setCurrentPage] = React.useState(1)
  const [table, setTable] = React.useState([])
  const [search, setSearch] = React.useState('')
  const { data, error, loading, refetch } = useFetch({
    fn: (search, currentPage) => getAllSubCategories(search, currentPage),
  })

  const handleSearch = withErrorHandling(async (search) => {
    refetch(search)
  })
  useEffect(() => {
    document.title = 'Categories - Admin'
  }, [])
  useEffect(() => {
    if (search === '') refetch(search, currentPage)
  }, [search])
  useEffect(() => {
    refetch(search, currentPage)
  }, [currentPage])

  return (
    <div className={''} {...props}>
      <Section title={'Categories'} className={'lg:pb-2'}>
        <Search
          handleSearch={handleSearch}
          search={search}
          setSearch={setSearch}
          className={'top-0'}
        />
        <ActionBar className={'top-0'}>
          <></>
          <NavLink to={'/categories/new'}>
            <Button>
              <AddIcon className={'h-6 w-6'} />
              Add Category
            </Button>
          </NavLink>
        </ActionBar>
      </Section>

      {loading ? (
        <Loader />
      ) : error ? (
        <p>Error</p>
      ) : (
        <SectionBody>
          <CaptionLayout
            results={data?.totalCount}
            caption={'Categories present'}
          >
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              table={table}
              pages={data?.paginationResult?.numberOfPages}
            />
          </CaptionLayout>
          <div
            className={cn(
              'custom-scroll relative max-h-[45vh] w-full overflow-auto md:max-h-[60vh]',
              className,
            )}
            {...props}
          >
            <Table data={data?.data} columns={columns} setTable={setTable} />
            {/* Fade */}
            <div className="pointer-events-none sticky bottom-0 left-0 h-16 w-full bg-gradient-to-t from-background to-transparent" />
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            table={table}
            pages={data?.paginationResult?.numberOfPages}
          />
        </SectionBody>
      )}
    </div>
  )
}
export default Categories
