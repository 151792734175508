import instance from './axios-instance'

export const getAllCustomers = async (keyword = '', page = 1) => {
  const { data } = await instance.get(
    `/api/v1/admin/users?page=${page}&keyword=${keyword}`,
  )
  const users = data?.data?.filter((user) => {
    return user.role === 'user'
  })
  return { ...data, data: users }
}
export const getCustomerById = async (id) => {
  const { data } = await instance.get(`/api/v1/admin/users/${id}`)
  return data
}
export const updateCustomer = async ({ id, payload }) => {
  const { data } = await instance.put(`/api/v1/admin/users/${id}`, payload)
  return data
}
export const deleteCustomer = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/users/${id}`)
  return data
}
