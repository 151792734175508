import React from 'react'
import { cn } from '../../utils/helperFunctions'

const Overlay = ({ children, onClick, className, ...props }) => {
  return (
    <div
      className={cn(
        'absolute top-0 z-10 h-full w-full bg-foreground/50',
        className,
      )}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  )
}

export default Overlay
