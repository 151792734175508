import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import ActionBar from '../../components/ActionBar'
import Search from '../../components/Search'
import Table from '../../components/Table'
import Button from '../../components/shared/Button'
import { AddIcon } from '../../components/shared/Icons'
import Loader from '../../components/shared/Loader'
import Pagination from '../../components/shared/Pagination'
import useFetch from '../../hooks/useFetch'
import CaptionLayout from '../../layouts/CaptionLayout'
import Section from '../../layouts/Section'
import SectionBody from '../../layouts/SectionBody'
import { getAllAttributes } from '../../utils/Apis/attributes'
import { cn } from '../../utils/helperFunctions'
import { columns } from './COLUMNS'
import { withErrorHandling } from '../../utils/withErrorHandling'

const Attributes = ({ className, ...props }) => {
  const { data, error, loading, refetch } = useFetch({
    fn: (search, currentPage) => getAllAttributes(search, currentPage),
  })
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const handleSearch = withErrorHandling(async (search) => {
    refetch(search)
  })
  useEffect(() => {
    document.title = 'Attributes - Admins'
  }, [])
  useEffect(() => {
    if (search === '') refetch(search, currentPage)
  }, [search]) // eslint-disable-line
  useEffect(() => {
    refetch(search, currentPage)
  }, [currentPage]) // eslint-disable-line
  return (
    <div className={className} {...props}>
      <Section title={'Attributes'} className={'lg:pb-2'}>
        <Search
          handleSearch={handleSearch}
          search={search}
          setSearch={setSearch}
          className={'top-0'}
        />
        <ActionBar className={'top-0'}>
          <></>
          <NavLink to={'/attributes/new'}>
            <Button>
              <AddIcon className={'h-6 w-6'} />
              Add New Attribute
            </Button>
          </NavLink>
        </ActionBar>
      </Section>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>Error</p>
      ) : (
        <SectionBody>
          <CaptionLayout
            results={data?.totalCount}
            caption={'Attribute present'}
          >
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pages={data?.paginationResult?.numberOfPages}
            />
          </CaptionLayout>
          <div
            className={cn(
              'custom-scroll relative max-h-[45vh] w-full overflow-auto md:max-h-[60vh]',
              className,
            )}
            {...props}
          >
            <Table data={data?.data} columns={columns} />
            {/* Fade */}
            <div className="pointer-events-none sticky bottom-0 left-0 h-16 w-full bg-gradient-to-t from-background to-transparent" />
          </div>

          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pages={data?.paginationResult?.numberOfPages}
          />
        </SectionBody>
      )}
    </div>
  )
}

export default Attributes
