import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Button from '../components/shared/Button'
import { login, logout } from '../utils/Apis/auth'
import { hasPermissionForAdmin } from '../utils/hasPermissionForAdmin'
// import { cn } from '../utils/helperFunctions'
import { getProfileDetails } from '../utils/Apis/me'

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm()
  const [isLoading, setIsLoading] = React.useState(false)

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      const response = await login({
        ...data,
      })

      if (response?.token) {
        const isAdmin = await hasPermissionForAdmin()
        if (isAdmin !== true) {
          toast.error('You are not authorized to access this page')
          return
        }
        localStorage.setItem('token', response.token)
        const me = await getProfileDetails()
        localStorage.setItem('id', me?.user?._id)
        window.location.replace('/dashboard')
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    localStorage.removeItem('token')
    logout()
  }, [])
  return (
    <div
      className="flex h-screen items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: 'url(./img/login_bg.jpg)' }}
    >
      <div className="mt-10 flex w-full max-w-md flex-col gap-6 rounded-xl bg-white p-8 shadow-xl">
        <img
          src="./new_logo_grey.png"
          alt="Company Logo"
          className="h-45 w-45 mx-auto object-contain"
        />
        <h1 className="text-center text-3xl font-semibold text-gray-800">
          Admin Login
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="flex items-center border-b py-2">
            <label
              htmlFor="email"
              className="w-1/5 pr-4 text-right font-medium"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="flex-1 rounded-lg border p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              {...register('email', { required: true })}
              required
            />
          </div>
          <div className="flex items-center border-b py-2">
            <label
              htmlFor="password"
              className="w-1/5 pr-4 text-right font-medium"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="flex-1 rounded-lg border p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              {...register('password', { required: true })}
              required
            />
          </div>
          <Button
            type="submit"
            disabled={!isValid || isLoading}
            className={`mt-4 w-full rounded-lg p-2 text-white ${isValid && !isLoading ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-300 text-gray-500'}`}
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                Loading...{' '}
              </span>
            ) : (
              'Login'
            )}
          </Button>
        </form>
      </div>
    </div>
  )
}

export default Login
