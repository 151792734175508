import React, { useEffect, useState } from 'react'
import Loader from '../../components/shared/Loader'
import Pagination from '../../components/shared/Pagination'
import Table from '../../components/Table'
import useFetch from '../../hooks/useFetch'
import CaptionLayout from '../../layouts/CaptionLayout'
import Section from '../../layouts/Section'
import SectionBody from '../../layouts/SectionBody'
import {
  getAllSubscriptions,
  deleteSubscription,
} from '../../utils/Apis/subscription'
import { cn } from '../../utils/helperFunctions'
import { columns } from './COLUMNS'

const Newsletter = ({ className }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { data, error, loading, refetch } = useFetch({
    fn: getAllSubscriptions,
    args: [currentPage],
  })

  useEffect(() => {
    document.title = 'Newsletter Subscriptions - Admin'
  }, [])

  useEffect(() => {
    refetch(currentPage)
  }, [currentPage, refetch])

  return (
    <div className={className}>
      <Section title="Newsletter Subscriptions" className="lg:pb-2" />
      {loading ? (
        <Loader />
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <SectionBody>
          <CaptionLayout
            results={data?.totalCount}
            caption="Newsletter present"
          >
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pages={data?.paginationResult?.numberOfPages}
            />
          </CaptionLayout>
          <div
            className={cn(
              'custom-scroll relative max-h-[45vh] w-full overflow-auto md:max-h-[60vh]',
              className,
            )}
          >
            <Table data={data?.data} columns={columns} />
            <div className="pointer-events-none sticky bottom-0 left-0 h-16 w-full bg-gradient-to-t from-background to-transparent" />
          </div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pages={data?.paginationResult?.numberOfPages}
          />
        </SectionBody>
      )}
    </div>
  )
}

export default Newsletter
