import instance from './axios-instance'

export const getAllShippingCharges = async (keyword = '', page = 1) => {
  const { data } = await instance.get(
    `/api/v1/shippingCharges?keyword=${keyword}&page=${page}`,
  )
  return data
}

export const getShippingCharge = async (id) => {
  const { data } = await instance.get(`/api/v1/shippingCharges/${id}`)
  return data
}

export const createShippingCharge = async ({
  name,
  type,
  country,
  shippingCharge,
  cartValue,
  isFree,
}) => {
  const { data } = await instance.post(`/api/v1/admin/shippingCharges`, {
    name,
    type,
    country,
    shippingCharge,
    cartValue,
    isFree,
  })
  return data
}
export const deleteShippingCharge = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/shippingCharges/${id}`)
  return data
}
export const updateShippingCharge = async ({ id, payload }) => {
  const { data } = await instance.put(
    `/api/v1/admin/shippingCharges/${id}`,
    payload,
  )
  return data
}
