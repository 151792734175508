import { createColumnHelper } from '@tanstack/react-table'
import ActionButton from '../../components/shared/ActionButton'
import { EditIcon, ViewIcon } from '../../components/shared/Icons'
import { NavLink } from 'react-router-dom'
import formate from 'date-format'
const columnHelper = createColumnHelper()
export const columns = [
  columnHelper.accessor('_id', {
    id: 'S/N',
    header: 'ID',
    cell: (info) => info.row.index + 1,
  }),
  columnHelper.accessor('orderItems', {
    header: 'Name',
    cell: (info) => info.getValue()[0]?.name,
  }),
  columnHelper.accessor('createdAt', {
    header: 'Order Date',
    cell: (info) => formate.asString('dd/MM/yyyy', new Date(info.getValue())),
  }),
  columnHelper.accessor('user', {
    header: 'Created By',
    cell: (info) => info.getValue()?.name,
  }),
  columnHelper.accessor('totalAmount', {
    header: 'Order Value',
    cell: (info) => <span>${info.getValue()}</span>,
  }),
  columnHelper.accessor('orderStatus', {
    header: 'Status',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('_id', {
    id: 'View',
    header: <span className="opacity-0">'View'</span>,
    cell: (info) => (
      <NavLink to={`/orders/view/${info.getValue()}`}>
        <ActionButton>
          <ViewIcon />
          View
        </ActionButton>
      </NavLink>
    ),
  }),
]

export const orderItemsColumns = [
  columnHelper.accessor('name', {
    header: 'Product Details',
    cell: (info) => info.getValue(),
  }),
  columnHelper.group({
    id: 'i',
    columns: [
      columnHelper.accessor('quantity', {
        header: 'quantity',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('price', {
        header: 'Rate',
        cell: (info) => <span>${info.getValue()}</span>,
      }),
      columnHelper.accessor('taxAmount', {
        header: 'Tax',
        cell: (info) => <span>${info.getValue()}</span>,
      }),
      columnHelper.accessor('itemsPrice', {
        header: 'Sub',
        cell: (info) => <span>${info.getValue()}</span>,
      }),
      columnHelper.accessor('shippingAmount', {
        header: 'Shipping Charges',
        cell: (info) => <span>${info.getValue()}</span>,
      }),
      columnHelper.accessor('totalAmount', {
        id: 'total',
        cell: (info) => <span>${info.getValue()}</span>,
      }),
    ],
    footer: (props) => (
      <div className="border">
        Total = $ {props.table.getRowModel().rows[0].original.totalAmount}
      </div>
    ),
  }),
]
