import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import SelectOption from '../../components/SelectOption'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import CheckBox from '../../components/shared/CheckBox'
import useFetch from '../../hooks/useFetch'
import {
  deleteDataSheet,
  getProductById,
  updateProduct,
} from '../../utils/Apis/products'
import { getAllSubCategories } from '../../utils/Apis/subCategory'
import BulkPricing from '../../components/BulkPricing'
import {
  cn,
  convertArrayToObject,
  convertToTitle,
  isObject,
  isPrimitiveDataType,
} from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'
// import { FaFilePdf } from 'react-icons/fa'
import { DeleteIcon } from '../../components/shared/Icons'

const fields = [
  'name',
  'Manufacturer',
  'CTYEA_Part_Number',
  'Manufacturer_Part_Number',
  'price',
  'description',
  'numOfReviews',
  'ratings',
  'stock',
  'Weight',
].map((item) => ({
  name: item,
  required: ![
    'description',
    'numOfReviews',
    'ratings',
    'CTYEA_Part_Number',
  ].includes(item),
}))

const EditProduct = ({ className, ...props }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: categories } = useFetch({
    fn: () => getAllSubCategories('', 1, 10000),
  })
  const { data: product } = useFetch({ fn: () => getProductById(id) })

  const [images, setImages] = useState()
  const [dataSheet, setDataSheet] = useState()
  const [isDeleted, setIsDeleted] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [attributes, setAttributes] = useState([])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm({ mode: 'onBlur' })
  const handleDeleteDataSheet = async () => {
    if (!isDeleted || dataSheet) return
    await deleteDataSheet(id)
  }
  const onSubmit = withErrorHandling(async (data) => {
    const bulkPricing = document.querySelectorAll('.bulk_pricing')
    let bulkPricingData = Array.from(bulkPricing).map((item) => {
      const [qty, unitPrice] = item.querySelectorAll('.bulk_pricing input')
      return { Qty: qty.value, UnitPrice: unitPrice.value }
    })
    bulkPricingData = bulkPricingData.filter(
      (item) => item.Qty && item.UnitPrice,
    )

    let attributes = document.querySelectorAll('.attributes')
    attributes = Array.from(attributes).map((attr) => {
      const input = attr.querySelector('.attributes input')
      const key = attr.querySelector('label').innerText
      const value = input.value
      return { key, value }
    })

    attributes = convertArrayToObject(attributes)

    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (
        key === 'sup_category' ||
        key === 'createdBy' ||
        key === '_id' ||
        key === 'category' || 
        key === 'datasheet' ||
        !isPrimitiveDataType(data[key])
      )
        return
      formData.append(key, data[key])
    })

    if (images?.length > 0) {
      Object.keys(images).forEach((key) => {
        formData.append('image', images[key])
      })
    }

    if (dataSheet) {
      formData.append('datasheet', dataSheet)
    }

    if (isObject(attributes)) {
      Object.keys(attributes).forEach((key) => {
        formData.append(`product_Attributes.${key}`, attributes[key])
      })
    }

    if (selectedCategory) {
      const parentName = categories?.data?.find(
        (supCategory) => supCategory.name === selectedCategory,
      )?.parentCategory?.name
      formData.append(
        'sup_category',
        selectedCategory?.name || selectedCategory,
      )
      if (parentName) formData.append('category', parentName)
    }

    bulkPricingData.forEach((item, i) => {
      formData.append(`bulk_Pricing[${i}][Qty]`, item.Qty)
      formData.append(`bulk_Pricing[${i}][UnitPrice]`, item.UnitPrice)
    })

    const response = await updateProduct({ id, payload: formData })
    if (response?.product) {
      toast.success('Product Updated successfully')
      await handleDeleteDataSheet()
      setTimeout(() => {
        navigate(`/products/view/${response.product._id}`)
      }, 2000)
    }
  })

  const handleOnImageChange = (e) => {
    if (e.target.files.length > 0) setImages(e.target.files)
  }
  const handleDataSheetChange = (e) => {
    if (e.target.files.length > 0) setDataSheet(e.target.files[0])
    else setDataSheet(null)
  }
  const toggleIsDeleted = useCallback(() => {
    setIsDeleted((prev) => !prev)
  }, [])

  // TODO: Remove this function
  // const removeAttribute = (index) => {
  //   setAttributes(attributes.filter((_, i) => i !== index))

  // }

  useEffect(() => {
    if (categories?.data?.length > 0 && !selectedCategory) {
      setSelectedCategory(categories?.data[0].name)
    }
  }, [categories]) //eslint-disable-line

  useEffect(() => {
    if (selectedCategory && categories?.data) {
      const category = categories?.data.find(
        (category) => category.name === selectedCategory,
      )
      setAttributes(category?.attributes || [])
    }
  }, [selectedCategory]) //eslint-disable-line

  useEffect(() => {
    if (product?.product) {
      const attributesValues = Object.keys(
        product?.product?.product_Attributes || {},
      ).map((key) => {
        return { value: product?.product?.product_Attributes[key], name: key }
      })
      setAttributes(attributesValues)
      reset(product.product)
      setSelectedCategory(product?.product?.sup_category)
      const bulkPricing = document.querySelectorAll('.bulk_pricing')
      Array.from(bulkPricing).forEach((item, index) => {
        let [qty, unitPrice] = item.querySelectorAll('.bulk_pricing input')
        qty.value = product.product.bulk_Pricing[index].Qty
        unitPrice.value =
          product.product.bulk_Pricing[index].UnitPrice.split(' ')[1] ||
          product.product.bulk_Pricing[index].UnitPrice
      })
    }
  }, [product]) //eslint-disable-line

  useEffect(() => {
    if (attributes.length > 0) {
      attributes.forEach((attr) => {
        setValue(`product_Attributes.${attr.name}`, attr.value)
      })
    }
  }, [attributes, setValue])

  return (
    <div
      className={cn(
        'custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/products'}>All Products</NavLink>
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
      >
        {fields.map((field) => (
          <Input
            key={field.name}
            register={register(field.name, { required: field.required })}
            errorMsg={errors[field.name]?.message || ''}
            label={convertToTitle(field.name)}
            required={field.required}
            className="w-full"
          />
        ))}

        <SelectOption
          label="Manufacturer Lead Time Unit"
          register={register('manufacturerLeadTimeUnit')}
          options={[
            { name: 'Days', value: 'Days' },
            { name: 'Weeks', value: 'Weeks' },
            { name: 'Months', value: 'Months' },
            { name: 'Years', value: 'Years' },
          ]}
          style={{ width: '100%' }}
        />
        <Input
          label="Manufacturer Lead Time"
          type="text"
          register={register('manufacturerLeadTime')}
          placeholder="Enter Manufacturer Lead Time"
          style={{ width: '100%' }}
        />
        <Input
          label="Image"
          type="file"
          onChange={handleOnImageChange}
          multiple
          required={false}
        />
        <Input
          label={
            <div className="flex flex-col items-start gap-1">
              <span>Data sheet</span>
              {product?.product?.datasheet && !dataSheet && (
                <>
                  <button type="button" className="text-xs">
                    {isDeleted ? (
                      <p onClick={toggleIsDeleted}>Undo</p>
                    ) : (
                      <div
                        onClick={toggleIsDeleted}
                        className="flex items-center text-red-500"
                      >
                        <DeleteIcon />
                        <span className="text-xs">Delete old datasheet</span>
                      </div>
                    )}
                  </button>
                </>
              )}
            </div>
          }
          type="file"
          onChange={handleDataSheetChange}
          accept=".pdf"
          required={false}
        />
        {categories?.data && categories?.data?.length > 0 && (
          <SelectOption
            label="Category"
            value={selectedCategory}
            options={categories?.data?.map((category) => ({
              value: category.name,
              name: category.name,
            }))}
            register={register('sup_category')}
            onChange={(e) => setSelectedCategory(e.target.value)}
          />
        )}
        <div className="flex w-full items-start">
          <CheckBox
            register={register('IsQuote')}
            label="Is Quote"
            id="IsQuote"
          />
        </div>
        <div className="flex w-full flex-col items-start gap-3">
          <h3 className="font-bold">Product Attributes</h3>
          <ul className="flex w-full flex-col gap-3 lg:w-[40%]">
            {attributes?.map((attr, index) => (
              <li
                key={index}
                className="attributes flex w-full flex-col items-start gap-2"
              >
                <label htmlFor={attr?.name}>{attr.name}</label>
                <input
                  id={attr?.name}
                  type="text"
                  defaultValue={attr?.value}
                  className="w-full rounded-lg border p-2"
                />
              </li>
            ))}
          </ul>
        </div>
        {product?.product?.bulk_Pricing?.length > 0 && (
          <BulkPricing number={product?.product?.bulk_Pricing?.length} />
        )}
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <ButtonSubmit disabled={!isValid} type="submit">
              Update Product
            </ButtonSubmit>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditProduct
