import instance from './axios-instance'

export const getAllSubCategories = async (
  keyword = '',
  page = 1,
  select = 1,
) => {
  const { data } = await instance.get(
    `/api/v1/categories?keyword=${keyword}&page=${page}&select=${select}`,
  )
  return data
}
export const createNewSubCategory = async (payload) => {
  const { data } = await instance.post('/api/v1/admin/categories', payload)
  return data
}
export const getSubCategory = async (id) => {
  const { data } = await instance.get(`/api/v1/categories/${id}`)
  return data
}
export const updateSubCategory = async ({ id, payload }) => {
  const { data } = await instance.put(`/api/v1/admin/categories/${id}`, payload)
  return data
}
export const deleteSubCategory = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/categories/${id}`)
  return data
}
