import React from 'react'
import { cn } from '../utils/helperFunctions'

const Section = ({ children, title, className, ...props }) => {
  return (
    <section
      className={cn(
        'flex flex-col items-center justify-center gap-2 border-b border-primary/20 px-2 pb-[1.5rem] lg:relative lg:pb-[2rem]',
        className,
      )}
      {...props}
    >
      <h1 className="text-[1.5rem] font-normal capitalize lg:text-[1.75rem]">
        {title}
      </h1>
      {children}
    </section>
  )
}

export default Section
