import instance from './axios-instance'

export const getProfileDetails = async () => {
  const { data } = await instance.get('/api/v1/me')
  return data
}

export const updatePassword = async ({ oldPassword, password }) => {
  const { data } = await instance.put('/api/v1/password/update', {
    oldPassword,
    password,
  })
  return data
}

export const updateMe = async (payload) => {
  const { data } = await instance.put('/api/v1/me/update', payload)
  return data
}

export const uploadAvatar = async (payload) => {
  const { data } = await instance.put('/api/v1/me/upload_avatar', payload)
  return data
}
