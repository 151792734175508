import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { closeSidebar } from '../../redux/slices/sidebarSlice'
import { logout } from '../../utils/Apis/auth'
import { MenuIcon } from '../shared/Icons'
import Image from '../shared/Image'
import { Item } from './Item'
import { links } from './links'
export const NavList = () => {
  const { pathname } = useLocation()
  const { open } = useSelector((state) => state.sidebar)
  const dispatch = useDispatch()
  const handleLogout = async () => {
    try {
      const response = await logout()
      localStorage.removeItem('token')
      if (response?.message) {
        toast.success(response.message)
        setTimeout(() => {
          window.location.href = '/login'
        }, 1000)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  return (
    <nav className="flex h-full flex-1 flex-col gap-[2rem] overflow-auto bg-secondary pb-[50px] pl-[50px] pr-[15px] pt-[30px] text-secondary-foreground">
      <div className="flex items-center justify-between gap-4">
        <NavLink className={'w-fit shrink-0'} to={'/'}>
          <Image src="/logo.png" alt="Logo" className="h-auto w-[190px]" />
        </NavLink>
        {open && (
          <button
            onClick={() => dispatch(closeSidebar())}
            className="hidden shrink-0 md:block"
          >
            <MenuIcon size={24} />
          </button>
        )}
      </div>
      <div className="flex flex-col">
        <h4 className="mb-4 text-2xl font-medium">Creatyea Admin</h4>
        <ul className="sidebar-list flex flex-col gap-[1rem] pl-4">
          {links.map((link) => (
            <Item
              key={link.title}
              title={link.title}
              path={link.path}
              icon={link.icon}
              activeIcon={link.activeIcon}
              isActive={pathname.includes(link.path)}
            />
          ))}
          <Item
            title="Logout"
            icon="/img/admin/logout.png"
            onClick={handleLogout}
            type="button"
          />
        </ul>
      </div>
    </nav>
  )
}
