import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import ModalContainer from '../../components/ModalContainer'
import Modal from '../../components/ModalContainer/Modal'
import Overlay from '../../components/ModalContainer/Overlay'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import DeleteButton from '../../components/shared/DeleteButton'
import { DeleteIcon } from '../../components/shared/Icons'
import useFetch from '../../hooks/useFetch'
import {
  deleteAttribute,
  getAttribute,
  updateAttribute,
} from '../../utils/Apis/attributes'
import { getAllparentCategory } from '../../utils/Apis/category'
import { cn } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'

const EditAttributes = ({ className, ...props }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useFetch({ fn: () => getAttribute(id) })
  const [open, setOpen] = React.useState(false)
  const { data: parentCategories } = useFetch({ fn: getAllparentCategory})
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm()

  const onSubmit = withErrorHandling(async (data) => {
    const checkboxes = document.querySelectorAll('.checkboxes')
    const checked = Array.from(checkboxes).filter(
      (checkbox) => checkbox.checked,
    )
    const checkedValues = checked.map((checkbox) => checkbox.value)
    if (checkedValues.length === 0) {
      return toast.error('Please select at least one category')
    }
    const response = await updateAttribute({
      id,
      payload: {
        name: data?.name,
        parentCategories: checkedValues,
      },
    })
    if (response?.attribute) {
      toast.success('Attribute updated successfully')
      setTimeout(() => {
        navigate('/attributes')
      }, 2000)
    }
  })

  const handleDelete = withErrorHandling(async () => {
    setOpen(false)
    const response = await deleteAttribute(id)
    if (response?.message) {
      toast.success('Attribute deleted successfully')
      setTimeout(() => {
        navigate('/attributes')
      }, 2000)
    }
  })

  useEffect(() => {
    if (data?.attribute) {
      reset({
        name: data?.attribute.name,
      })

      // Setting the checked status for checkboxes
      setTimeout(() => {
        data?.attribute?.parentCategories?.forEach((category) => {
          const checkbox = document.getElementById(category?._id)
          if (checkbox) checkbox.checked = true
        })
      }, 100)
    }
  }, [data, reset])

  return (
    <div
      className={cn(
        'custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/attributes'}>All Attributes</NavLink>
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
      >
        <Input
          label="Name"
          id="name"
          name="name"
          register={register('name', {
            required: 'Name is required',
          })}
          errorMsg={errors?.name?.message}
          required={true}
        />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="flex w-full flex-col items-start gap-6 md:flex-row">
            <p>Categories Applicable:</p>
            <ul className="flex flex-col gap-3">
              {parentCategories?.parentCategories?.map(
                (category, index) => (
                  <CheckBox
                    key={index}
                    label={category?.name}
                    id={category?._id}
                    name={category?.name}
                    value={category?._id}
                    className={'checkboxes'}
                  />
                ),
              )}
            </ul>
          </div>
        )}
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <ButtonSubmit disabled={!isValid} type="submit">
              Update Attribute
            </ButtonSubmit>
            <DeleteButton onClick={() => setOpen(true)}>
              <DeleteIcon />
              Delete Attribute
            </DeleteButton>
          </div>
        </div>
      </form>
      {open && (
        <ModalContainer>
          <Modal>
            <div className="flex h-full w-full flex-col rounded-lg bg-background p-6">
              <p className="text-lg font-medium">Are you sure?</p>
              <p className="text-sm text-muted-foreground">
                Do you really want to delete this Attribute? This process cannot
                be undone.
              </p>
              <div className="mt-4 flex gap-4">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <DeleteButton onClick={handleDelete}>
                  <DeleteIcon />
                  Delete Attribute
                </DeleteButton>
              </div>
            </div>
          </Modal>
          <Overlay onClick={() => setOpen(false)} />
        </ModalContainer>
      )}
    </div>
  )
}

const CheckBox = ({
  label,
  name,
  id,
  value,
  className,
  register,
  ...props
}) => {
  return (
    <fieldset className="flex items-center gap-3" {...props}>
      <input
        id={id}
        type="checkbox"
        className={cn('checkboxes size-6', className)}
        name={name}
        value={value}
        {...register}
      />
      <label htmlFor={id} className="text-xl">
        {label}
      </label>
    </fieldset>
  )
}

export default EditAttributes
