import React, { useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Record from '../../components/shared/Record'
import ViewLayout from '../../layouts/ViewLayout'
import useFetch from '../../hooks/useFetch'
import Button from '../../components/shared/Button'
import { EditIcon } from '../../components/shared/Icons'
import { getCustomerById } from '../../utils/Apis/customers'
const ViewCustomer = () => {
  const { id } = useParams()
  const { data, loading, error } = useFetch({ fn: () => getCustomerById(id) })
  const customer = data?.user

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>
  return (
    <ViewLayout
      sectionTitle={'Customer Details'}
      allButtonTitle={'All Customers'}
      allButtonPath={'/customers'}
      action={
        <Button>
          <EditIcon />
          <NavLink to={`/customers/edit/${id}`}>Update Customer</NavLink>
        </Button>
      }
    >
      <Record label={'Name'} value={customer?.name} />
      <Record label={'Email'} value={customer?.email} />
      <Record label={'Phone'} value={customer?.phone} />
      {customer?.CompanyName && (
        <Record label={'Company Name'} value={customer?.CompanyName} />
      )}
      {customer?.website && (
        <Record label={'Website'} value={customer?.website} />
      )}
    </ViewLayout>
  )
}

export default ViewCustomer
