import React from 'react'
import { cn } from '../../utils/helperFunctions'

const ModalContainer = ({ children, className, ...props }) => {
  return (
    <div
      className={cn('fixed left-0 top-0 z-40 h-full w-full', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default ModalContainer
