import React from 'react'
import { cn } from '../../utils/helperFunctions'

export const TableRow = ({ className, children, ...props }) => {
  return (
    <tr className={cn('', className)} {...props}>
      {children}
    </tr>
  )
}
