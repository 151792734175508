import instance from './axios-instance'

export const getAllAttributes = async (keyword = '', page = 1) => {
  const { data } = await instance.get(
    `/api/v1/attributes?keyword=${keyword}&page=${page}`,
  )
  return data
}
export const createAttribute = async ({ name, parentCategories = [] }) => {
  const { data } = await instance.post('/api/v1/admin/attributes', {
    name,
    parentCategories,
  })
  return data
}

export const getAttribute = async (id) => {
  const { data } = await instance.get(`/api/v1/attributes/${id}`)
  return data
}
export const updateAttribute = async ({ id, payload }) => {
  const { data } = await instance.put(`/api/v1/admin/attributes/${id}`, payload)
  return data
}
export const deleteAttribute = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/attributes/${id}`)
  return data
}
