import React, { useEffect, useState } from 'react'
import Search from '../../components/Search'
import Table from '../../components/Table'
import Loader from '../../components/shared/Loader'
import Pagination from '../../components/shared/Pagination'
import useFetch from '../../hooks/useFetch'
import CaptionLayout from '../../layouts/CaptionLayout'
import Section from '../../layouts/Section'
import SectionBody from '../../layouts/SectionBody'
import { getAllCustomers } from '../../utils/Apis/customers'
import { cn } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'
import { columns } from './COLUMNS'

const Customers = ({ className, ...props }) => {
  const { data, error, loading, refetch } = useFetch({
    fn: (search, currentPage) => getAllCustomers(search, currentPage),
  })
  const [search, setSearch] = React.useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const handleSearch = withErrorHandling(async (search) => {
    refetch(search)
  })
  useEffect(() => {
    document.title = 'Customers - Admin'
  }, [])
  useEffect(() => {
    if (search === '') refetch(search, currentPage)
  }, [search])
  useEffect(() => {
    refetch(search, currentPage)
  }, [currentPage])
  return (
    <div className={className} {...props}>
      <Section title={'Customers Accounts'}>
        <Search
          handleSearch={handleSearch}
          search={search}
          setSearch={setSearch}
          className={'top-0'}
        />{' '}
      </Section>
      {loading ? (
        <Loader />
      ) : error ? (
        <p>Error</p>
      ) : (
        <SectionBody>
          <CaptionLayout
            results={data?.totalCount}
            caption={'Customers present'}
          >
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pages={data?.paginationResult?.numberOfPages}
            />
          </CaptionLayout>
          <div
            className={cn(
              'custom-scroll relative max-h-[45vh] w-full overflow-auto md:max-h-[60vh]',
              className,
            )}
            {...props}
          >
            <Table data={data?.data} columns={columns} />
            {/* Fade */}
            <div className="pointer-events-none sticky bottom-0 left-0 h-16 w-full bg-gradient-to-t from-background to-transparent" />
          </div>

          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pages={data?.paginationResult?.numberOfPages}
          />
        </SectionBody>
      )}
    </div>
  )
}

export default Customers
