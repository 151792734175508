import React, { useEffect, useState } from 'react'

const PrivateRoutes = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
      window.location.href = '/login'
    }
  }, [])
  return <>{isAuthenticated ? children : null}</>
}

export default PrivateRoutes
