import React from 'react'
import { cn } from '../utils/helperFunctions'
import { CloseIcon, SearchIcon } from './shared/Icons'
import Button from './shared/Button'
const Search = ({
  className,
  search = '',
  setSearch = () => {},
  handleSearch = () => {},
  placeholder,
  ...props
}) => {
  return (
    <div
      className={cn('flex h-full xl:absolute xl:right-0 xl:h-10', className)}
      {...props}
    >
      <input
        type="text"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder={placeholder || 'Search'}
        className="w-full max-w-[200px] rounded-s-md border border-primary/20 p-2 outline-none placeholder:capitalize focus:border-transparent focus:ring-2"
      />
      <Button onClick={() => handleSearch(search)} className={'rounded-none'}>
        <SearchIcon />
      </Button>
      <Button
        className={'rounded-none rounded-e border-l border-foreground/20'}
        onClick={() => setSearch('')}
      >
        <CloseIcon />
      </Button>
    </div>
  )
}

export default Search
