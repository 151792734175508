import instance from './axios-instance'

export const getAllOrders = async (keyword = '', page = 1) => {
  const { data } = await instance.get(
    `/api/v1/admin/orders?page=${page}&keyword=${keyword}`,
  )
  return data
}
export const getOrderById = async (id) => {
  const { data } = await instance.get(`/api/v1/orders/${id}`)
  return data?.order
}
export const updateOrder = async ({ id, payload }) => {
  const { data } = await instance.put(`api/v1/admin/orders/${id}`, payload)
  return data
}
export const deleteOrder = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/orders/${id}`)
  return data
}
