import { cn } from '../../utils/helperFunctions'

const CheckBox = ({
  label,
  name,
  id,
  value,
  className,
  register,
  ...props
}) => {
  return (
    <fieldset className="flex items-center gap-3" {...props}>
      <input
        id={id}
        type="checkbox"
        className={cn('checkboxes size-6', className)}
        name={name}
        value={value}
        {...register}
      />
      <label htmlFor={id} className="text-xl">
        {label}
      </label>
    </fieldset>
  )
}

export default CheckBox
