import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import OpenSidebarButton from '../components/OpenSidebarButton'
import { cn } from '../utils/helperFunctions'
import MainContainer from './MainContainer'
const PageLayout = memo(({ children, className, ...props }) => {
  const { open } = useSelector((state) => state.sidebar)
  return (
    <MainContainer>
      <article
        className={cn(
          'w-full rounded-lg border bg-background px-[1.25rem] py-[2rem]',
          {
            'md:w-full': open,
            'md:w-[70%]': !open,
          },
          className,
        )}
        {...props}
      >
        <OpenSidebarButton />
        <Outlet />
      </article>
    </MainContainer>
  )
})

export default PageLayout
