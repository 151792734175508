import axios from 'axios'
const baseURL = 'https://api.creatyea.com'
// const baseURL = 'http://localhost:4000'
const instance = axios.create({
  baseURL,
  withCredentials: true,
  timeout: 60 * 1000,
  timeoutErrorMessage: 'Request Timeout, Please try again later.',
})
export default instance
