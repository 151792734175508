import React from 'react'
import { AddIcon, DeleteIcon } from './shared/Icons'
import { cn } from '../utils/helperFunctions'
import Button from './shared/Button'

const ActionBar = ({ children, className, ...props }) => {
  let right, left
  if (Array.isArray(children) && children.length > 1) {
    ;[left, right] = children
  } else {
    left = children
  }
  return (
    <div
      className={cn(
        'flex w-full items-center justify-between gap-3',
        className,
      )}
      {...props}
    >
      <section className="flex w-full flex-col items-start justify-between gap-2 lg:flex-row lg:justify-stretch lg:gap-12">
        {left}
      </section>
      <section className="flex flex-col items-center gap-4 md:flex-row">
        {right}
      </section>
    </div>
  )
}

export default ActionBar
