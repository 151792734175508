import React from 'react'
import { cn } from '../../utils/helperFunctions'

const Button = ({
  className,
  type = 'button',
  onClick,
  children,
  disabled = false,
  ...props
}) => {
  return (
    <button
      className={cn(
        'flex items-center justify-center text-nowrap rounded-lg bg-primary px-3 py-2 capitalize text-primary-foreground transition-colors duration-300 ease-in-out hover:bg-blue-600',
        className,
      )}
      onClick={onClick}
      {...props}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  )
}

export default Button
