export const links = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: '/img/admin/dashboard.png',
    activeIcon: '/img/admin/dashboard_active.png',
  },
  {
    title: 'Customers',
    path: '/customers',
    icon: '/img/admin/customers.png',
    activeIcon: '/img/admin/customers_active.png',
  },
  {
    title: 'Orders',
    path: '/orders',
    icon: '/img/admin/orders.png',
    activeIcon: '/img/admin/orders_active.png',
  },
  {
    title: 'Newsletter',
    path: '/newsletter',
    icon: '/img/admin/newsletter.png',
    activeIcon: '/img/admin/newsletter_active.png',
  },
  {
    title: 'Categories',
    path: '/categories',
    icon: '/img/admin/categories.png',
    activeIcon: '/img/admin/categories_active.png',
  },

  {
    title: 'Parent Category',
    path: '/parentCategory',
    icon: '/img/admin/categories.png',
    activeIcon: '/img/admin/categories_active.png',
  },
  {
    title: 'Attributes',
    path: '/attributes',
    icon: '/img/admin/attributes.png',
    activeIcon: '/img/admin/attributes_active.png',
  },
  {
    title: 'Products',
    path: '/products',
    icon: '/img/admin/products.png',
    activeIcon: '/img/admin/products_active.png',
  },
  {
    title: 'Shipping Charges',
    path: '/shipping_charges',
    icon: '/img/admin/shipping_charges.png',
    activeIcon: '/img/admin/shipping_charges_active.png',
  },
  {
    title: 'Admin Users',
    path: '/admin_users',
    icon: '/img/admin/admin_users.png',
    activeIcon: '/img/admin/admin_users_active.png',
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: '/img/admin/profile.png',
    activeIcon: '/img/admin/profile_active.png',
  },
]
