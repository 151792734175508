import React from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import SelectOption from '../../components/SelectOption'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import { addAdmin } from '../../utils/Apis/admins'
import { cn } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'

const NewAdmin = ({ className, ...props }) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  })
  const onSubmit = withErrorHandling(async (data) => {
    const response = await addAdmin({
      ...data,
    })
    if (response?.user) {
      toast.success('User created successfully')
      setTimeout(() => {
        navigate(`/admin_users/view/${response.user._id}`)
      }, 2000)
    }
  })
  const fields = [
    {
      label: 'Name',
      id: 'name',
      name: 'name',
      register: register('name', {
        required: 'name is required',
      }),
      errorMsg: errors?.name?.message,
      required: true,
    },
    {
      label: 'Email',
      id: 'email',
      name: 'email',
      register: register('email', {
        required: 'email is required',
      }),
      errorMsg: errors?.email?.message,
      required: true,
    },
    {
      label: 'Password',
      id: 'password',
      name: 'password',
      register: register('password', {
        required: 'password is required',
        minLength: {
          value: 6,
          message: 'password must be at least 6 characters',
        },
      }),
      errorMsg: errors?.password?.message,
      required: true,
    },
    {
      label: 'Phone',
      id: 'phone',
      name: 'phone',
      register: register('phone', {
        required: 'phone is required',
      }),
      errorMsg: errors?.phone?.message,
      required: true,
    },
  ]
  return (
    <div
      className={cn(
        ' custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/admin_users'}>All Admins</NavLink>
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
      >
        {fields.map((field) => (
          <Input key={field.id} {...field} />
        ))}

        <SelectOption
          label="role"
          id="role"
          name="role"
          register={register('role', {
            required: 'role is required',
          })}
          errorMsg={errors?.role?.message}
          required={true}
          options={['user', 'admin']}
        />

        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <ButtonSubmit disabled={!isValid} type="submit">
              Add admin
            </ButtonSubmit>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewAdmin
