import React from 'react'
import { NavLink } from 'react-router-dom'
import Button from '../../components/shared/Button'
import Section from '../Section'
import Body from './Body'
import Grid from './Grid'

const ViewLayout = ({
  sectionTitle,
  allButtonTitle,
  allButtonPath,
  action,
  children,
  className,
}) => {
  return (
    <div className="custom-scroll h-full overflow-auto px-2">
      <Section title={sectionTitle} className={'border-none md:relative'}>
        {allButtonTitle && (
          <NavLink to={allButtonPath}>
            <Button className={'md:absolute md:right-0 md:top-0'}>
              {allButtonTitle}
            </Button>
          </NavLink>
        )}
      </Section>
      <Body>
        <Grid className={className}>{children}</Grid>
        {action}
      </Body>
    </div>
  )
}

export default ViewLayout
