import React from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '../../components/shared/Button'
import DeleteButton from '../../components/shared/DeleteButton'
import { DeleteIcon, EditIcon } from '../../components/shared/Icons'
import Record from '../../components/shared/Record'
import useFetch from '../../hooks/useFetch'
import ViewLayout from '../../layouts/ViewLayout'
import { deleteSubCategory, getSubCategory } from '../../utils/Apis/subCategory'
import { formatDate } from '../../utils/formatDate'
import { withErrorHandling } from '../../utils/withErrorHandling'
import Overlay from '../../components/ModalContainer/Overlay'
import ModalContainer from '../../components/ModalContainer'
import Modal from '../../components/ModalContainer/Modal'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const ViewCategory = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, error, loading } = useFetch({ fn: () => getSubCategory(id) })
  const [open, setOpen] = React.useState(false)

  const handleDelete = withErrorHandling(async () => {
    setOpen(false) // Assuming setOpen is part of the component's state management
    const response = await deleteSubCategory(id)

    if (response?.message) {
      toast.success('Category deleted successfully')
      setTimeout(() => {
        navigate('/categories') // Correct usage inside setTimeout
      }, 2000)
    } else {
      toast.error('Failed to delete the category' || response.error) // Using the error message from the response if available
    }
  })
  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  return (
    <ViewLayout
      sectionTitle="Category Details"
      allButtonTitle="All Categories"
      allButtonPath="/categories"
      action={
        <div className="flex flex-col gap-2 md:flex-row">
          <Button>
            <EditIcon />
            <NavLink to={`/categories/edit/${id}`}>Update Category</NavLink>
          </Button>
          <DeleteButton onClick={() => setOpen(true)}>
            <DeleteIcon />
            Delete Category
          </DeleteButton>
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        {data?.category?.imageUrl && (
          <img
            src={data?.category?.imageUrl}
            className="h-auto w-full rounded-xl md:w-[200px]"
            alt={data?.category?.name}
          />
        )}
        <h1 className="text-xl font-bold">{data?.category?.name}</h1>
      </div>
      <Record
        label="Parent Category"
        value={data?.category?.parentCategory?.name}
      />
      <Record
        label="Created At"
        value={formatDate(data?.category?.createdAt)}
      />
      <NavLink
        className="text-primary"
        to={`/admin_users/view/${data?.category?.createdBy?._id}`}
      >
        <Record label="Created By" value={data?.category?.createdBy?.name} />
      </NavLink>
      {data?.category?.updatedBy && (
        <Record label="Updated By" value={data?.category?.updatedBy?.name} />
      )}
      <Accordion allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Attributes</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul>
              {data?.category?.attributes &&
                data?.category?.attributes?.map((attribute) => (
                  <li key={attribute._id} className="flex flex-col">
                    {attribute.name}
                  </li>
                ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
      <Record label="Description" value={data?.category?.description} />
      {open && (
        <ModalContainer>
          <Modal>
            <div className="flex h-full w-full flex-col rounded-lg bg-background p-6">
              <p className="text-lg font-medium">Are you sure?</p>
              <p className="text-sm text-muted-foreground">
                Do you really want to delete this Category? This process cannot
                be undone.
              </p>
              <div className="mt-4 flex gap-4">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <DeleteButton onClick={handleDelete}>
                  <DeleteIcon />
                  Delete Category
                </DeleteButton>
              </div>
            </div>
          </Modal>
          <Overlay onClick={() => setOpen(false)} />
        </ModalContainer>
      )}
    </ViewLayout>
  )
}

export default ViewCategory
