import React from 'react'
import { cn } from '../../utils/helperFunctions'

const Grid = ({ children, className }) => {
  return (
    <div
      className={cn(
        'grid w-full grid-cols-1 gap-x-12 gap-y-4 overflow-auto md:grid-cols-2 md:px-20',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Grid
