import { toast } from 'react-toastify'

export const withErrorHandling = (fn) => {
  return async (...args) => {
    try {
      await fn(...args)
    } catch (error) {
      
      console.log(error)
      if (error?.response?.data?.message) {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.removeItem('token')
          return (window.location.href = '/login')
        }
        toast.error(error.response.data.message)
      } else {
        toast.error(error.message)
      }
    }
  }
}
