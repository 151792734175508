import React from 'react'
import { cn } from '../../utils/helperFunctions'

const ActionButton = ({ children, className, onClick, ...props }) => {
  return (
    <button
      className={cn('flex items-center gap-2 text-primary', className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

export default ActionButton
