import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Button from '../../components/shared/Button'
import { EditIcon } from '../../components/shared/Icons'
import Loader from '../../components/shared/Loader'
import Record from '../../components/shared/Record'
import useFetch from '../../hooks/useFetch'
import ViewLayout from '../../layouts/ViewLayout'
import { getAdmin } from '../../utils/Apis/admins'
import { formatDate } from '../../utils/formatDate'
import { isPrimitiveDataType } from '../../utils/helperFunctions'
import Image from '../../components/shared/Image'
const ViewAdmin = () => {
  const { id } = useParams()
  const { data, loading } = useFetch({ fn: () => getAdmin(id) })
  const admin = data?.user
  return (
    <ViewLayout
      sectionTitle={'Admin Details'}
      allButtonTitle={'All Admins'}
      allButtonPath={'/admin_users'}
      action={
        <Button>
          <EditIcon />
          <NavLink to={`/admin_users/edit/${id}`}>Update Admin</NavLink>
        </Button>
      }
    >
      {loading ? (
        <div className="flex w-full items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            {admin?.avatar?.url && (
              <div className="relative size-[150px] rounded-full md:size-[200px]">
                <Image
                  className={
                    'absolute h-full w-full rounded-full object-cover object-center'
                  }
                  src={admin?.avatar?.url}
                  alt={admin?.name}
                />
              </div>
            )}
            <h1 className="text-2xl font-bold">{admin?.name}</h1>
          </div>
          <div className="flex flex-col gap-2">
            {admin &&
              Object.keys(admin).length > 0 &&
              Object.keys(admin).map((key, index) => {
                if (
                  key === 'createdBy' ||
                  key === 'updatedAt' ||
                  key === '__v' ||
                  key === '_id' ||
                  !isPrimitiveDataType(admin[key]) ||
                  key === 'name'
                )
                  return null
                return (
                  <Record
                    key={index}
                    label={key}
                    value={
                      key === 'createdAt' ? formatDate(admin[key]) : admin[key]
                    }
                  />
                )
              })}
          </div>
        </>
      )}
    </ViewLayout>
  )
}

export default ViewAdmin
