import React from 'react'
import Button from './Button'
import { cn } from '../../utils/helperFunctions'

const ButtonSubmit = ({
  children,
  disabled,
  className,
  type = 'submit',
  onClick,
  ...props
}) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      className={cn('bg-green-600 hover:bg-green-700', className, {
        'cursor-not-allowed bg-gray-400 hover:bg-gray-400': disabled,
      })}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ButtonSubmit
