import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Section from '../layouts/Section'
import SectionBody from '../layouts/SectionBody'

const paragraphs = [
  'You can select an option from the left side of the screen to proceed.',
  'Customers section lets you view all your customers and see their details.',
  'Orders section lets you view all the orders in the system and update their status.',
  'Newsletter Subscriptions section lets you view all the email ids that are subscribed for newsletters.',
  'Categories section lets you add, view, and modify the category of products in the system.',
  'Attributes section lets you add, view, and modify the attributes associated with each category of products.',
  'Products section lets you add, view, and modify the products in the system.',
  'Shipping Charges section lets you add, view, and modify the shipping charges for new orders.',
  'Admin Users section lets you add, view, and modify the administrator users for managing this system.',
  'Profile lets you see your profile details and update your password.',
]

const Dashboard = ({ className }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    document.title =
      pathname === '/dashboard'
        ? 'Dashboard - Admin'
        : 'Creatyea Technology | Admin'
    // Cleanup function to reset the title when the component unmounts
    return () => {
      document.title = 'Creatyea Technology | Admin'
    }
  }, [pathname])

  return (
    <div className={className}>
      <Section title="Dashboard" className="border-none lg:pb-1" />
      <SectionBody className="custom-scroll max-h-[70vh] overflow-auto p-0">
        <h2 className="sticky top-0 bg-background text-2xl font-normal">
          Hi Administrator,
        </h2>
        <div className="flex flex-col gap-2">
          {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </SectionBody>
    </div>
  )
}

export default Dashboard
