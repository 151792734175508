import instance from './axios-instance'

export const getAllProducts = async (
  keyword = '',
  page = 1,
  sortProduct = '',
) => {
  let queryString = `/api/v1/products?page=${page}`

  if (keyword !== '') {
    queryString += `&keyword=${keyword}`
  }

  if (sortProduct !== '') {
    queryString += `&sort=${sortProduct}`
  }

  const { data } = await instance.get(queryString)
  return data
}

export const getProductById = async (id) => {
  const { data } = await instance.get(`/api/v1/products/${id}`)
  return data
}

export const createProduct = async (payload) => {
  const { data } = await instance.post(`/api/v1/admin/products`, payload)
  return data
}

export const updateProduct = async ({ id, payload }) => {
  const { data } = await instance.put(`/api/v1/admin/products/${id}`, payload)
  return data
}

export const deleteProduct = async (id) => {
  const { data } = await instance.delete(`/api/v1/admin/products/${id}`)
  return data
}

export const deleteDataSheet = async (id) => {
  const { data } = await instance.delete(
    `/api/v1/admin/products/${id}/deletedatasheet`,
  )
  return data
}
