import React from 'react'
import { cn } from '../../utils/helperFunctions'
import { tableCellClasses } from '.'
export const TableData = ({ className, children, ...props }) => {
  return (
    <td
      className={cn('w-fit align-top', tableCellClasses, className)}
      {...props}
    >
      {children}
    </td>
  )
}
