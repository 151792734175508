import { createColumnHelper } from '@tanstack/react-table'
import { NavLink } from 'react-router-dom'
import ActionButton from '../../components/shared/ActionButton'
import { EditIcon, ViewIcon } from '../../components/shared/Icons'
import { formatDate } from '../../utils/formatDate'
const columnHelper = createColumnHelper()
export const columns = [
  columnHelper.accessor('_id', {
    header: 'ID',
    cell: (info) => info.row.index + 1,
  }),
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('Manufacturer_Part_Number', {
    header: 'Mfr Part No.',
  }),
  columnHelper.accessor('CTYEA_Part_Number', {
    header: 'CTYEA Part No.',
  }),
  columnHelper.accessor('createdAt', {
    header: 'Modified Date',
    cell: (info) => formatDate(info.getValue()),
  }),
  columnHelper.accessor('sup_category', {
    header: 'Category',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('_id', {
    id: 'View',
    header: <span className="opacity-0">'View'</span>,
    cell: (info) => (
      <NavLink to={`/products/view/${info.getValue()}`}>
        <ActionButton>
          <ViewIcon />
          View
        </ActionButton>
      </NavLink>
    ),
  }),
  columnHelper.accessor('_id', {
    id: 'edit',
    header: <span className="opacity-0">'Edit'</span>,
    cell: (info) => (
      <NavLink to={`/products/edit/${info.getValue()}`}>
        <ActionButton className={'text-destructive'}>
          <EditIcon />
          Edit
        </ActionButton>
      </NavLink>
    ),
  }),
]
