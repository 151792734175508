import React from 'react'
import Button from './Button'
import { cn } from '../../utils/helperFunctions'

const DeleteButton = ({
  className,
  type = 'button',
  onClick,
  children,
  disabled = false,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      type={type}
      className={cn(
        'border border-destructive bg-transparent text-destructive hover:bg-destructive hover:text-destructive-foreground',
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children || 'Delete'}
    </Button>
  )
}

export default DeleteButton
