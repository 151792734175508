import instance from './axios-instance'

export const login = async ({ email, password }) => {
  const { data } = await instance.post('/api/v1/login', {
    email,
    password,
  })
  return data
}
export const logout = async () => {
  const { data } = await instance.get('/api/v1/logout')
  return data
}
