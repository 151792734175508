import React, { useEffect, useState } from 'react'
import ViewLayout from '../../layouts/ViewLayout'
import Record from '../../components/shared/Record'
import { useNavigate, useParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import Table from '../../components/Table'
import { orderItemsColumns } from './COLUMNS'
import {
  convertToTitle,
  isObject,
  isPrimitiveDataType,
} from '../../utils/helperFunctions'
import Button from '../../components/shared/Button'
import { DeleteIcon, EditIcon } from '../../components/shared/Icons'
import DeleteButton from '../../components/shared/DeleteButton'
import formate from 'date-format'
import { toast } from 'react-toastify'
import { deleteOrder, getOrderById, updateOrder } from '../../utils/Apis/orders'
import { withErrorHandling } from '../../utils/withErrorHandling'
import ModalContainer from '../../components/ModalContainer'
import Modal from '../../components/ModalContainer/Modal'
import Overlay from '../../components/ModalContainer/Overlay'

const ViewOrder = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [status, setStatus] = useState('')
  const [paymentInfo, setPaymentInfo] = useState({})
  const [openAlert, setOpenAlert] = useState(false)
  const [open, setOpen] = useState(false)
  const { data, error, loading } = useFetch({
    fn: () => getOrderById(id),
  })

  const handleOnChange = (e) => {
    setStatus(e.target.value)
  }

  const handleSubmit = withErrorHandling(async () => {
    const response = await updateOrder({
      id,
      payload: {
        status,
      },
    })
    if (response?.success) {
      toast.success('Order Updated Successfully')
      window.location.reload()
    }
    setOpen(false)
  })

  const handleDelete = withErrorHandling(async () => {
    setOpenAlert(false)
    const response = await deleteOrder(id)
    if (response?.success) {
      toast.success('Order Deleted Successfully')
      setTimeout(() => {
        navigate('/orders')
      }, 2000)
    }
  })

  const updatePaymentInfo = withErrorHandling(async () => {
    const response = await updateOrder({
      id,
      payload: {
        paymentInfo,
      },
    })
    if (response?.success) {
      toast.success('Payment Info Updated Successfully')
    }
  })

  const togglePaymentInfo = () => {
    const updatedPaymentInfo = {
      ...paymentInfo,
      status: paymentInfo.status === 'Not Paid' ? 'Paid' : 'Not Paid',
    }
    setPaymentInfo(updatedPaymentInfo)
  }

  useEffect(() => {
    if (data?.orderStatus) {
      setStatus(data?.orderStatus)
      setPaymentInfo(data?.paymentInfo || {})
    }
  }, [data])

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  let { orderItems, ...rest } = data
  orderItems = orderItems?.map((item) => ({
    ...item,
    ...rest,
  }))

  const statuses = ['Shipped', 'Processing', 'Delivered']
  return (
    <ViewLayout
      sectionTitle={'Order Details'}
      allButtonPath={'/orders'}
      allButtonTitle={'All Orders'}
      action={
        <div className="flex flex-col gap-2 md:flex-row">
          <Button onClick={() => setOpen(true)}>
            <EditIcon />
            Update Order Status
          </Button>
          <DeleteButton onClick={() => setOpenAlert(true)}>
            <DeleteIcon />
            Delete Order
          </DeleteButton>
        </div>
      }
      className={'gap-12 md:flex md:flex-wrap lg:px-10'}
    >
      <Column title={'Order Details'}>
        <Record label={'Order ID'} value={data?._id} />
        <Record
          label={'Order Date'}
          value={formate.asString('dd/MM/yyyy', new Date(data?.createdAt))}
        />
        <Record label={'Status'} value={data?.orderStatus} />
      </Column>
      <Column title={'Billing Address'}>
        {data?.paymentMethod && (
          <Record label={'Payment Method'} value={data?.paymentMethod} />
        )}
        {paymentInfo && (
          <div className="flex flex-col items-start gap-2">
            <Record
              label={'Payment Info'}
              value={convertToTitle(paymentInfo.status)}
            />
            <Button
              className={'w-fit bg-secondary/70 hover:bg-secondary'}
              onClick={togglePaymentInfo}
            >
              <EditIcon />
              {paymentInfo.status === 'Not Paid'
                ? 'Mark as Paid'
                : 'Mark as Not Paid'}
            </Button>
            <Button onClick={updatePaymentInfo}>Save</Button>
          </div>
        )}
      </Column>
      {data?.shippingInfo &&
      isObject(data?.shippingInfo) &&
      Object.keys(data?.shippingInfo).length > 0 ? (
        <Column title={'Shipping Address'}>
          {Object.keys(data?.shippingInfo).map((key) => {
            return (
              <Record
                label={convertToTitle(key)}
                value={
                  isPrimitiveDataType(data?.shippingInfo[key])
                    ? data?.shippingInfo[key]
                    : null
                }
              />
            )
          })}
        </Column>
      ) : null}

      <div className="w-full md:col-span-3">
        <h2 className="text-2xl">Order Items</h2>
        <div
          className={
            'custom-scroll relative max-h-[45vh] w-full overflow-auto md:max-h-[60vh]'
          }
        >
          <Table columns={orderItemsColumns} data={orderItems} />
        </div>
      </div>
      {/* Modal */}
      {open && (
        <div className="fixed left-0 top-0 z-20 h-full w-full">
          <div className="absolute left-[50%] top-[50%] z-40 flex w-[60%] translate-x-[-50%] translate-y-[-50%] flex-col items-center justify-center gap-4 rounded-lg bg-white px-5 py-6 md:w-[400px]">
            <select onChange={handleOnChange} value={status}>
              {statuses.map((status) => (
                <option value={status} key={status}>
                  {status}
                </option>
              ))}
            </select>
            <Button onClick={handleSubmit}>Save</Button>
          </div>
          {/* Overlay */}
          <div
            onClick={() => setOpen(false)}
            className="absolute z-10 h-full w-full bg-foreground/30"
          ></div>
        </div>
      )}
      {openAlert && (
        <ModalContainer>
          <Modal>
            <div className="flex h-full w-full flex-col rounded-lg bg-background p-6">
              <p className="text-lg font-medium">Are you sure?</p>
              <p className="text-sm text-muted-foreground">
                Do you really want to delete this Order? This process cannot be
                undone.
              </p>
              <div className="mt-4 flex gap-4">
                <Button onClick={() => setOpenAlert(false)}>Cancel</Button>
                <DeleteButton onClick={handleDelete}>
                  <DeleteIcon />
                  Delete Order
                </DeleteButton>
              </div>
            </div>
          </Modal>
          <Overlay onClick={() => setOpenAlert(false)} />
        </ModalContainer>
      )}
    </ViewLayout>
  )
}

const Column = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-2xl font-semibold">{title}</h2>
      {children}
    </div>
  )
}

export default ViewOrder
