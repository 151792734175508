import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from '../../components/shared/Button'
import DeleteButton from '../../components/shared/DeleteButton'
import { DeleteIcon, EditIcon } from '../../components/shared/Icons'
import Record from '../../components/shared/Record'
import Table from '../../components/Table'
import useFetch from '../../hooks/useFetch'
import ViewLayout from '../../layouts/ViewLayout'
import { deleteProduct, getProductById } from '../../utils/Apis/products'
import { formatDate } from '../../utils/formatDate'
import {
  cn,
  convertToTitle,
  isPrimitiveDataType,
} from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'
import ModalContainer from '../../components/ModalContainer'
import Modal from '../../components/ModalContainer/Modal'
import Overlay from '../../components/ModalContainer/Overlay'
import { useNavigate } from 'react-router-dom'
import { FaFilePdf } from 'react-icons/fa'
const columnHelper = createColumnHelper()
const ViewProduct = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, error, loading } = useFetch({ fn: () => getProductById(id) })
  const [open, setOpen] = React.useState(false)

  const handleDelete = withErrorHandling(async () => {
    setOpen(false)
    const response = await deleteProduct(id)
    if (response?.message) {
      toast.success('Product deleted successfully')
      setTimeout(() => {
        navigate('/products')
      }, 2000)
    }
  })
  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>
  return (
    <ViewLayout
      sectionTitle={'Product Details'}
      allButtonTitle={'All products'}
      allButtonPath={'/products'}
      action={
        <div className="flex flex-col gap-2 md:flex-row">
          <Button>
            <EditIcon />
            <NavLink to={`/products/edit/${id}`}>Update Product</NavLink>
          </Button>
          <DeleteButton onClick={() => setOpen(true)}>
            <DeleteIcon />
            Delete Product
          </DeleteButton>
        </div>
      }
    >
      <div className="flex flex-col items-start gap-3">
        {data?.product?.image && data?.product?.image.length > 0 && (
          <img
            src={data?.product?.image[0]}
            className="h-auto w-full rounded-lg md:w-[200px]"
            alt="Product"
          />
        )}
        <h1 className="text-2xl font-bold">
          {data?.product?.name || 'Product Details'}
        </h1>
      </div>
      {data?.product &&
        Object.keys(data.product).length > 0 &&
        Object.keys(data.product).map((key, index) => {
          if (
            key === '_id' ||
            key === '__v' ||
            key === 'IsQuote' ||
            key === 'datasheet' ||
            key.toLowerCase().includes('Manufacturer'.toLowerCase()) ||
            key.toLowerCase().includes('name')
          )
            return null
          if (!isPrimitiveDataType(data.product[key])) return null
          return (
            <Record
              key={index}
              label={convertToTitle(key)}
              value={
                key === 'createdAt' || key === 'updatedAt'
                  ? formatDate(data.product[key])
                  : data.product[key]
              }
            />
          )
        })}
      {data?.product?.datasheet && data?.product?.datasheet !== '' && (
        <Record
          label="Datasheet"
          value={
            <div>
              <a
                href={data?.product?.datasheet}
                target="_blank"
                className="flex items-center gap-2 hover:underline"
                rel="noreferrer"
                download
              >
                <FaFilePdf className="text-red-500" />
                Download Datasheet
              </a>
            </div>
          }
        />
      )}
      {data?.product && (
        <Record
          label="Is Quote"
          value={
            <span
              className={cn({
                'text-green-500': data?.product?.IsQuote,
                'text-destructive': !data?.product?.IsQuote,
              })}
            >
              {data?.product?.IsQuote ? 'YES' : 'NO'}
            </span>
          }
        />
      )}

      {data?.product && Object.keys(data?.product).length > 0 && (
        <div className="flex flex-col gap-3">
          {Object.keys(data?.product).map((key, index) => {
            if (key.toLowerCase().includes('Manufacturer'.toLowerCase())) {
              return (
                <Record
                  key={index}
                  label={convertToTitle(key)}
                  value={data.product[key]}
                />
              )
            }
          })}
        </div>
      )}

      {data?.product?.product_Attributes &&
        Object.keys(data?.product?.product_Attributes).length > 0 && (
          <div className="flex flex-col gap-2">
            <h2 className="font-bold">Attributes:</h2>
            <table className="flex flex-col gap-3">
              {Object.keys(data?.product?.product_Attributes).map(
                (key, index) => (
                  <tr key={index} className="border p-2">
                    <td className="grid grid-cols-2 place-items-center">
                      <span className="font-semibold">
                        {convertToTitle(key)}
                      </span>
                      <span className="italic">
                        {data?.product?.product_Attributes[key]}
                      </span>
                    </td>
                  </tr>
                ),
              )}
            </table>
          </div>
        )}

      {data?.product &&
        data?.product?.bulk_Pricing &&
        data?.product?.bulk_Pricing?.length > 0 && (
          <div className="flex flex-col gap-3">
            <h2 className="font-bold">Bulk Pricing:</h2>
            <Table
              columns={[
                ...Object.keys(data?.product?.bulk_Pricing[0])
                  .filter((item) => item !== '_id')
                  .map((key) =>
                    columnHelper.accessor(key, {
                      header: convertToTitle(key),
                      cell: (info) => info.getValue(),
                    }),
                  ),
                columnHelper.accessor('ExtPrice', {
                  header: 'Ext Price',
                  cell: (info) => info.getValue(),
                }),
              ]}
              data={data?.product?.bulk_Pricing.map((item) => {
                delete item.ExtPrice
                const { Qty, UnitPrice } = item
                return {
                  ...item,
                  UnitPrice: Number(
                    UnitPrice.split(' ')[1] || UnitPrice,
                  ).toFixed(2),
                  ExtPrice: Number(
                    Qty * (UnitPrice?.split(' ')[1] || UnitPrice),
                  ).toFixed(2),
                }
              })}
              classNameForData={'text-center border-b'}
              classNameForHeader={'text-center border-b'}
            />
          </div>
        )}
      {open && (
        <ModalContainer>
          <Modal>
            <div className="flex h-full w-full flex-col rounded-lg bg-background p-6">
              <p className="text-lg font-medium">Are you sure?</p>
              <p className="text-sm text-muted-foreground">
                Do you really want to delete this Product? This process cannot
                be undone.
              </p>
              <div className="mt-4 flex gap-4">
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <DeleteButton onClick={handleDelete}>
                  <DeleteIcon />
                  Delete Product
                </DeleteButton>
              </div>
            </div>
          </Modal>
          <Overlay onClick={() => setOpen(false)} />
        </ModalContainer>
      )}
    </ViewLayout>
  )
}

export default ViewProduct
