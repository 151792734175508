import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openSidebar } from '../redux/slices/sidebarSlice'
import { MenuIcon } from './shared/Icons'
const OpenSidebarButton = () => {
  const { open } = useSelector((state) => state.sidebar)
  const dispatch = useDispatch()
  const handleOpenSidebar = () => {
    dispatch(openSidebar())
  }
  if (open) {
    return null
  }
  return (
    <button onClick={handleOpenSidebar} className="fixed left-3 top-3">
      <MenuIcon size={24} />
    </button>
  )
}

export default OpenSidebarButton
