import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import {
  createParentCategory,
} from '../../utils/Apis/category'
import { cn, isPrimitiveDataType } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'

const NewParentCategory = ({ className, ...props }) => {
  const navigate = useNavigate()
  const [image, setImage] = useState('')

    const {
      register,
      handleSubmit,
      formState: { errors, isValid },
    } = useForm()

  const onSubmit = withErrorHandling(async (data) => {
      const formData = new FormData()
      Object.keys(data).forEach((key) => {
        if (isPrimitiveDataType(data[key])) {
          formData.append(key, data[key])
        }
      })
    if (image) {
      formData.append('imageUrl', image)
    }
    const response = await createParentCategory(formData)
    if (response?.parentCategory) {
      toast.success('Category created successfully')
      setTimeout(() => {
        navigate(`/parentCategory/view/${response?.parentCategory?._id}`)
      }, 2000)
    }
  })

  return (
    <div
      className={cn(
        'custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/parentCategory'}>All Parent Categories</NavLink>
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
      >
        <Input
          label="Name"
          id="name"
          name="name"
          register={register('name', {
            required: 'Name is required',
          })}
          errorMsg={errors?.name?.message}
          required={true}
        />
        <div className="grid w-full grid-cols-4">
          <label className="col-span-1 font-semibold">Image</label>
          <div className="col-span-3 rounded-lg border p-2">
            <input
              className="w-full"
              type="file"
              onChange={(e) => {
                setImage(e.target.files[0])
              }}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <label>Description</label>
          <textarea
            {...register('description', {
              required: 'Description is Required',
            })}
            className="min-h-[200px] border p-3"
          ></textarea>
          {errors?.description?.message && (
            <p className="text-destructive">{errors?.description?.message}</p>
          )}
        </div>
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <ButtonSubmit disabled={!isValid} type="submit">
              Create Parent Category
            </ButtonSubmit>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewParentCategory
