import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Input from '../../components/Input'
import SelectOption from '../../components/SelectOption'
import Button from '../../components/shared/Button'
import ButtonSubmit from '../../components/shared/ButtonSubmit'
import CheckBox from '../../components/shared/CheckBox'
import useFetch from '../../hooks/useFetch'
import {
  getAllparentCategory,
  getParentCategoryAttributes,
} from '../../utils/Apis/category'
import { getSubCategory, updateSubCategory } from '../../utils/Apis/subCategory'
import { cn, isPrimitiveDataType } from '../../utils/helperFunctions'
import { withErrorHandling } from '../../utils/withErrorHandling'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const EditCategory = ({ className, ...props }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const {
    data: categories,
    error, // eslint-disable-line
    loading,
  } = useFetch({ fn: getAllparentCategory })

  const [attributeForParentCategory, setAttributeForParentCategory] = useState(
    [],
  )
  const [
    loadingAttributeForParentCategory,
    setLoadingAttributeForParentCategory,
  ] = useState(false)
  const [image, setImage] = useState('')
  const { data: subCategory } = useFetch({ fn: () => getSubCategory(id) })
  const [selectedCategory, setSelectedCategory] = React.useState('')
  const [options, setOptions] = useState([])
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm()
  const onSubmit = withErrorHandling(async (data) => {
    const checkboxes = document.querySelectorAll('.checkboxes')
    const checked = Array.from(checkboxes).filter(
      (checkbox) => checkbox.checked,
    )
    const checkedValues = checked.map((checkbox) => checkbox.value)
    if (checkedValues.length === 0) {
      return toast.error('Please select at least one Attribute')
    }
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (isPrimitiveDataType(data[key])) {
        formData.append(key, data[key])
      }
    })
    checkedValues.forEach((value) => {
      formData.append('attributes', value)
    })
    if (image) {
      formData.append('imageUrl', image)
    }
    const response = await updateSubCategory({
      id,
      payload: formData,
    })
    if (response?.category) {
      toast.success('Updated created successfully')
      setTimeout(() => {
        navigate(`/categories/view/${response?.category?._id}`)
      }, 2000)
    }
  })
  const fetchingParentCategoryAttributes = withErrorHandling(async (id) => {
    try {
      setLoadingAttributeForParentCategory(true)
      const res = await getParentCategoryAttributes(id)
      setAttributeForParentCategory(res)
      setLoadingAttributeForParentCategory(false)
    } catch (error) {
      setLoadingAttributeForParentCategory(false)
    }
  })
  const handleSelectAllAttributes = (e) => {
    const checkboxes = document.querySelectorAll('.checkboxes')
    Array.from(checkboxes).forEach((checkbox) => {
      checkbox.checked = e.target.checked
    })
  }
  useEffect(() => {
    if (categories?.data?.length > 0) {
      setSelectedCategory(categories?.data[0]?._id)
    }
  }, [categories])

  useEffect(() => {
    if (subCategory?.category) {
      reset({
        name: subCategory?.category.name,
        description: subCategory?.category?.description,
      })
    }
  }, [subCategory]) // eslint-disable-line
  useEffect(() => {
    if (categories?.parentCategories?.length > 0 && subCategory?.category) {
      const allCategories = categories?.parentCategories?.filter(
        (category) =>
          category?._id !== subCategory?.category?.parentCategory?._id,
      )
      setOptions(
        [subCategory?.category?.parentCategory, ...allCategories].map(
          (category) => ({
            name: category?.name,
            value: category?._id,
          }),
        ),
      )
      setSelectedCategory(subCategory?.category?.parentCategory?._id)
    }
  }, [subCategory, categories])

  useEffect(() => {
    if (selectedCategory) {
      fetchingParentCategoryAttributes(selectedCategory)
    }
  }, [selectedCategory]) // eslint-disable-line

  useEffect(() => {
    if (subCategory?.category?.attributes?.length > 0) {
      const attributeIds = subCategory?.category?.attributes.map(
        (attribute) => attribute?._id,
      )
      const checkboxes = document.querySelectorAll('.checkboxes')
      Array.from(checkboxes).forEach((checkbox) => {
        if (attributeIds.includes(checkbox.value)) {
          checkbox.checked = true
        }
      })
    }
  }, [attributeForParentCategory, subCategory])
  return (
    <div
      className={cn(
        ' custom-scroll flex h-full w-full flex-col items-end gap-6 overflow-auto px-2',
        className,
      )}
      {...props}
    >
      <Button>
        <NavLink to={'/categories'}>All Categories</NavLink>
      </Button>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center gap-8 lg:gap-6 lg:pl-[6rem]"
      >
        <Input
          label="Name"
          id="name"
          name="name"
          register={register('name', {
            required: 'Name is required',
          })}
          errorMsg={errors?.name?.message}
          required={true}
        />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <SelectOption
            label="Parent Category"
            id="parentCategory"
            name="parentCategory"
            register={register('parentCategory', {
              required: 'Parent Category is required',
            })}
            errorMsg={errors?.parentCategory?.message}
            required={true}
            options={options || []}
            onChange={(e) => setSelectedCategory(e.target.value)}
          />
        )}

        {loadingAttributeForParentCategory ? (
          <p>Loading Attributes....</p>
        ) : attributeForParentCategory?.data?.length ? (
          <div className="flex w-full items-start">
            <Accordion allowZeroExpanded className="flex items-start ">
              <AccordionItem>
                <div className="flex items-center gap-1">
                  <label htmlFor="selectAll" className="font-semibold">
                    Select All
                  </label>
                  <input
                    id="selectAll"
                    type="checkbox"
                    onChange={handleSelectAllAttributes}
                  />
                </div>
                <AccordionItemHeading>
                  <AccordionItemButton>Viewable Attributes</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul className="flex w-full flex-col items-start">
                    {attributeForParentCategory?.data?.map((attribute) => (
                      <CheckBox
                        id={attribute?._id}
                        label={attribute?.name}
                        name={attribute?.name}
                        value={attribute?._id}
                        className={'checkboxes'}
                      />
                    ))}
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        ) : null}
        <div className="grid w-full grid-cols-4">
          <label className="col-span-1 font-semibold">Image</label>
          <div className="col-span-3 rounded-lg border p-2">
            <input
              className="w-full"
              type="file"
              onChange={(e) => {
                setImage(e.target.files[0])
              }}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <label>Description</label>
          <textarea
            {...register('description', {
              required: 'Description is Required',
            })}
            className="min-h-[200px] border p-3"
          ></textarea>
          {errors?.description?.message && (
            <p className="text-destructive">{errors?.description?.message}</p>
          )}
        </div>
        <div className="flex flex-col items-center gap-3">
          <div className="flex flex-col items-center gap-2 md:flex-row">
            <ButtonSubmit disabled={!isValid} type="submit">
              Updated Category
            </ButtonSubmit>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditCategory
